@import "../../assets/styles/variables.scss";


.sidebar-wrapper {
  top: 0;
  z-index: 1000;
  position: fixed;
  width: 0;
  height: 100%;
  width: $sidebarWidth;
  overflow-y: auto;
  background: $menu-background;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  .iconAfterStripe {
    margin-left: 5px;
    margin-bottom: 10px;
    width: 6px;
  }
  &:not(.sub-menu) {
    border-right: 1px solid $menu-item-border;
  }

  ul.sidebar-nav > li.menu-item:last-child,
  ul.sidebar-nav > li.menu-item.sub:last-child {
    margin-bottom: 37px;
  }

  span.menu-icon {
    font-size: 22px;
  }

  &.collapsed {
    width: $sidebarCollapsedWidth;
    .menu-text {
      display: none !important;
    }
    .sidebar-nav {
      width: $sidebarCollapsedWidth;
    }
    .sub-menu {
      .sidebar-nav {
        width: $sidebarWidth;
      }
    }
  }

  .menu-text {
    padding-left: 20px;
    span {
      font-size: 13px;
      font-weight: 400;
    }
  }
}

@media only screen and (min-width: 768px) {
  .navigator.navbar-fixed-top.mobile {
    display: none;
  }
  .menu-item.mobile-extra {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  /* reset mobile CCSS */
  .navigator.navbar-fixed-top.mobile {
    position: fixed;
    top: 0px;
    right: 0px;
  }
  .menu-item.mobile-extra {
    display: block;
  }
  .fill {
    &.mobile {
      .sidebar-wrapper {
        width: $sidebarWidth;
        &.collapsed {
          transition: none;
          width: $sidebarCollapsedWidth;
        }
      }
      #page-content {
        .sidebar-wrapper {
          transition: all 0.5s ease;
        }
        &.have-submenu {
          margin-left: $sidebarCollapsedWidth;
          .navigator.mobile {
            left: $sidebarCollapsedWidth + $sidebarWidth;
          }
        }
        .navigator.mobile {
          left: $sidebarWidth;
          transition-delay: 0.25s;
          .page-title {
            display: none;
          }
        }
      }
    }
    .sidebar-wrapper {
      width: 0px;
      .sidebar-nav {
        .sidebar-brand {
          display: none;
        }
      }
      &.sub-menu {
        .sidebar-nav {
          top: 10px;
        }
      }
      &.collapsed {
        transition: none;
      }
    }
    #page-content {
      .sidebar-wrapper {
        transition: none;
      }
      margin-left: 0;
      &.collapsed {
        margin-left: 0;
      }
      .main-content,
      .main-content.content-with-submenu {
        margin-left: 0;
      }
      // navigation bar
      .navigator {
        &.mobile {
          display: flex;
          left: 0;
          z-index: 999;
          align-items: center;
          flex-flow: row;
          height: 50px;
          .container {
            height: $mobileNavbarheight;
            display: flex;
            align-items: center;
          }
        }
        &.desktop {
          display: none;
        }
      }
      .fill-height {
        height: calc(100% - #{$mobileNavbarheight});
        min-height: calc(100% - #{$mobileNavbarheight});
        .main-content {
          margin-top: $mobileNavbarheight;
        }
      }
      .content {
        overflow-y: auto;
        overflow-x: hidden;
      }
      //tab list
      .nav-tabs{
        .nav-item {
          padding: 12px 20px;
        }
      }
    }
  }
  div.have-submenu {
    margin: 0;
  }

  div.content-with-submenu {
    padding: 0px;
  }
  /* Apply mobile CSS */
  .modal-dialog,
  .card-dialog.modal-dialog,
  .confirm .modal-dialog {
    width: 100%;
    .modal-footer {
      button.btn {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  div.map-controlers-container.active {
    width: 90%;
  }
  .mobile {
    .have-submenu {
      margin-left: 50px;
    }
  }
  div.modal-dialog {
    margin-left: auto;
    margin-right: auto;
  }
  .menu-item.mobile-extra {
    ul.dropdown-menu {
      li a {
        color: $app-base-color;
      }
    }
  }
  .divider.sidebar-divider {
    border-top: 1px solid $menu-item-border;
  }
  
  .sidebar-nav li.menu-item.mobile-extra:hover {
    background-color: transparent;
  }
  form.map-select-zone {
    width: 350px;
    top: 35px;
  }
  .header-button-group {
    .group-left {
      .form-group {
        margin-top: 5px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}


@media only screen and (max-height: 849px) {
  .content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}