@import '../../assets/styles/variables.scss';

.react-autosuggest__container--open {
  .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    width: 100%;
    font-weight: 300;
    font-size: 14px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 2;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    color: $text;
    background-color: $dropdown-bg;
    border: 1px solid $borderColor;
    border-color: transparent;
  }
}
.assign-driver-container {
  .react-autosuggest__container--open {
    .react-autosuggest__suggestions-container {
      border: none;
    }
  }
}
.react-autosuggest__suggestion--highlighted {
  background-color: #3b3b3b;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 2px 5px;
  :hover {
    background-color: #3b3b3b;
  }
}
