@import '../../assets/styles/variables.scss';

.mapContainer {
  .mapqup {
    display: block;
    height: calc(100vh - 100px);
  }
}
p.contentMarker {
  display: flex;
  span:first-child {
    margin-right: 3px;
  }
}
.map-controlers-container {
  position: fixed;
  padding-top: $navbar-height;
  // margin-top: ($navbar-height + 40px);
  top: 0;
  right: 0;
  height: 100%;
  // box-shadow: 1px 2px 4px rgba(0, 0, 0, 1);
  width: 0px;
  .map-control-side-bar {
    display: none;
  }
  &.active {
    width: 550px;
    .map-control-side-bar {
      display: block;
    }
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 10px;
    left: 0;
    bottom: 0;
    background-color: $modalBodyBgr;
    z-index: 100000;
  }
}
.select-top-map {
  display: flex;
  justify-content: space-between;
}
.map-inner {
  display: flex;
  width: 100%;
  .fa-map-marker {
    color: $headerTitleTable;
    width: 24px;
    font-size: 24px;
  }
  span {
    width: calc(100% - 50px);
    margin-top: 2px;
  }
}
.total-show {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  color: $text;
  .button-show-all {
    display: none;
  }
  .total,
  .show-all {
    border-radius: 4px;
    background: $formBackground !important;
  }
  .total {
    width: 26%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    span {
      opacity: 0.3;
    }
    .total-number {
      font-size: 50px;
    }
  }
  .show-all {
    width: calc(74% - 10px);
    padding: 17px 25px;
    .show-all-list {
      display: flex;
      flex-wrap: wrap;
      .show-all-item,
      .show-all-item-first {
        width: 50%;
        .form-group {
          display: inline;
        }
        &:nth-child(even) {
          .cc-checkbox-wrapper label .checked-icon {
            left: 1.3px;
          }
        }
      }
      .show-all-item {
        margin-top: 10px;
      }
    }
    input.cc-checkbox[type='checkbox']:not(:checked) + label:before,
    input.cc-checkbox[type='checkbox']:checked + label:before {
      border-radius: 5px;
      background: $modalBodyBgr;
    }
    .cc-checkbox-wrapper label .checked-icon {
      color: $text;
    }
  }
}
.map-controlers {
  width: 100%;
  position: relative;
  z-index: 101;
  height: 100%;
  padding: 10px 20px;
  background: $modalBodyBgr;
  overflow: scroll;
}
.map-select-zone {
  position: absolute;
  right: 30px;
  top: 110px;
  .place-container {
    width: 250px;
  }
  .select-map-zone-option {
    margin-left: 10px;
  }
}
.map-select-zone.active {
  right: 560px;
}
.map-select-layer-tag {
  .btn-map > .btn:first-child {
    margin-left: 0;
    background-color: #fff;
    padding: 10px 30px !important;
    color: black;
  }
}
.map-select-layer {
  position: absolute;
  left: 220px;
  top: 46px;
  background-color: #fff;
  .place-container {
    width: 250px;
  }
  .select-map-zone-option {
    margin-left: 10px;
  }
  .cc-checkbox-wrapper label:before {
    background: #f5f4f4 !important;
  }
  .cc-checkbox-wrapper {
    margin-bottom: 10px !important;
  }
  .form-group:not(.cc-checkbox-wrapper) {
    margin-bottom: 0px;
  }
  .btn-toolbar .btn,
  .btn-toolbar .btn-map,
  .btn-toolbar .input-group {
    padding: 10px 20px !important;
    color: black;

  }
  .btn-map > .btn:first-child {
    margin-left: 0;
    background-color: #fff;
    padding: 10px 30px !important;
    color: black;
  }
  .btn-map.open .dropdown-toggle {
    box-shadow: none;
    margin-left: 0px !important;
    padding: 5px 30px !important;
  }
  .btn-primary:active,
  .btn-primary.active,
  .open > .dropdown-toggle.btn-primary {
    background-color: #fff;
  }
  .btn-primary:active:hover,
  .btn-primary.active:hover,
  .open > .dropdown-toggle.btn-primary:hover,
  .btn-primary:active:focus,
  .btn-primary.active:focus,
  .open > .dropdown-toggle.btn-primary:focus,
  .btn-primary:active.focus,
  .btn-primary.active.focus,
  .open > .dropdown-toggle.btn-primary.focus {
    background-color: #fff;
  }
  .cc-checkbox-wrapper label {
    color: #0c0c0c;
  }
  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus {
    background-color: #fff;
  }
}
.map-select-layer.active {
  left: 200px;
  top: 52px;
}
.map-select-layer-tag {
  position: absolute;
  left: 200px;
  top: 10px;
  background-color: #fff;
  .place-container {
    width: 250px;
  }
  .select-map-zone-option {
    margin-left: 10px;
  }
  .cc-checkbox-wrapper label:before {
    background: #f5f4f4 !important;
  }
  .cc-checkbox-wrapper {
    margin-bottom: 10px !important;
  }
  .form-group:not(.cc-checkbox-wrapper) {
    margin-bottom: 0px;
  }
  .btn-toolbar .btn,
  .btn-toolbar .btn-group,
  .btn-toolbar .input-group {
    padding: 10px 20px !important;
  }
  .btn-group > .btn:first-child {
    margin-left: 0;
    background-color: #fff;
    padding: 5px 30px !important;
  }
  .btn-group.open .dropdown-toggle {
    box-shadow: none;
    margin-left: 0px !important;
    padding: 5px 30px !important;
  }
  .btn-primary:active,
  .btn-primary.active,
  .open > .dropdown-toggle.btn-primary {
    background-color: #fff;
  }
  .btn-primary:active:hover,
  .btn-primary.active:hover,
  .open > .dropdown-toggle.btn-primary:hover,
  .btn-primary:active:focus,
  .btn-primary.active:focus,
  .open > .dropdown-toggle.btn-primary:focus,
  .btn-primary:active.focus,
  .btn-primary.active.focus,
  .open > .dropdown-toggle.btn-primary.focus {
    background-color: #fff;
  }
  .cc-checkbox-wrapper label {
    color: #0c0c0c;
  }
  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus {
    background-color: #fff;
  }
}
.map-select-layer-tag .active {
  left: 200px;
  top: 10px;
}
.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
  margin-left: 0px !important;
  padding: 5px !important;
}

.map-zone-group {
  display: flex !important;
}
.select-map-zone-option {
  white-space: nowrap;
  width: 250px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.show-buton-container {
  position: absolute;
  top: 40%;
  left: -23px;
  z-index: 100;
  cursor: pointer;
}
.show-button {
  border: thin solid #c4c4c4;
  width: 24px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 0 0 8px;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  background: $headerTitleTable;
}
.glyphicon-chevron-right:before,
.glyphicon-chevron-left:before {
  color: $text;
}

.total-drivers-container {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAABIBJREFUWMPFV12oFVUYnbLSJKheMk2Diig0KLiR7r3neiMopHooSqIeysfopQIjwQcz6CEy/AURtD8sUJrZe+61H0uypCKUEnqQoqzorVLSUu+ZmeOZ1tqz93jvOXO8595zpYHhzDmz97e/v7W+dYKgx6sYHpidG/FortXWPBbfpLH4KzUqszee+Zt9p+UjXBtM19XSgwtyozalsfoHn0UvN9aehCMbWiNLrpvywcW2gUszI1enWoxao0Y0Ee3HmVHPZrEUrUhcU+xeeBlvPmdayEyr57BmL+6z5R51ButXFfuHLplc1NHgXETwpTOS5kaum0w0reiu+diznuVxWTlQ7Bm6tqfNo7G8CV7/ajeiro2RpTdPNYsNI29BXxwsnRBHR98Pb5wwcn840vw209tvD9EGSrPTO9E1E6w5Fn1VHb4muHi6Gpm2zjkhP6/tCTacT/t0RF6XiTxWh2yAWr3YATV2Oxuun5pP2BPD4lbHHWdaRs6rXhDnNnqtXu9aR0CPMEOm3mEkgOWfIKDc3kb9wWZj6SxMTbiYJa2zhXUbXSnWVwxHkiHOPdSKIrgojcRtOOyFNJaf0uNeSWgMGZ0mJ8DplWmiFtFmlW3wBO4TrV1LLg8svZZ435sn4h7gf0tmxO8dRmP1A97tyHT4DN4vpSEaYKQWPUmo4PDTsLcd64+070dmfrPRa3V3Gsl97veHg7NavOWY7t9xESDFTGlTqydayeI5k6Zw7OFe2rCzYnx2SlqP5RvB+GjFUXy+ynoXu5fPmD4ELJ9BqkbdXwPKfhmblaARy+POm/t8nS7kxTPyJFzGMxtGHAs8X18I7Hctz4fLZvo58/870IjFcfflA9IkCOkTdnKq5YpCD13Vf/0Hrmwa9STFCmx+RFgDLbosgTwWZFEN5KpuFaewcS09nlqU8mVro4t9Dj6y4JvlFzBcou5F9A/g+fnUVFglBxzqeZ57PRGr76pAkFWyKHjkfp4B++/ad5HYHlDDeSLqGFA6HMC7752hI8We8OoJU441lrTKPYeR9jva11TB6fAhT8UnSY9UMp01HLqCveGmmDkfVC2FGznio64Tp8Wwuh7ntUB0f2Msz3IDQm4YNyBqoqpUEqi7mwPNOHzcRf4zm69+GInNHYOPQ4gDh5CkjKrd6MgDDvxYx5IUGY5JC86UeokWLnQT9BT7pE2QqFXOs4N1nGAZzKivy6YMH+xwEIPFzZD9XVGh5beulCuD+gjUAbdgZ50kaybqqRIxYldnw4qI75paPlYryYx8zw2iz7rOGULNDwvrRFsm2At2lqNpx+o6jmSmlSVk07ZH7g8HOn7if4jzy3JI56qWwD9lVFuqD9tIInX7GD15p9eT7TX3aefho8ngDb3Rp82E+sLxQ0YhQQFSprpUt5z1VWlItbZ/5A4PNXY7G86nfcLI63qC6tXLsTL1kGcw5pr1JabXDZZXrFNWvsl9xLmncjZcX9qC6tX9zTrRsx4EyRDnHVDra4RC/1moJdCFICUwHf+ep1bKY6pZogK3k14rhuvh+g8uL98RH43shQAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  padding-left: 35px;
  background-position: left center;
  line-height: 1;
  .total-text {
    font-size: 12px;
    font-weight: 700;
    margin: 0;
  }
  .total-number {
    font-size: 20px;
    font-weight: 700;
  }
}
.show-cars-options {
  background: $text;
  border: 2px solid #d1d1d1;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  table {
    width: 100%;
    td {
      text-align: center;
      vertical-align: top;
      padding: 0 5px;
      font-size: 12px;
    }
  }
}

/* remake map driver status base on old cc */
input.cc-checkbox.map-checkbox[type='checkbox']:checked,
input.cc-checkbox.map-checkbox[type='checkbox']:not(:checked) {
  + label.bookIn:before {
    background: $vancant;
  }
  + label.offered:before {
    background-color: $dispatch;
  }
  + label.booked:before {
    background-color: $onTheWay;
  }
  + label.arrived:before {
    background: $arrived;
  }
  + label.engaged:before {
    background: $pob;
  }
  + label.delivering:before {
    background: $pob;
  }
  + label.droppedOff:before {
    background: $dropOff;
  }
  + label.action:before {
    background: $waiting;
  }
  + label.pending:before {
    background: $waiting;
  }
  + label.bookOff:before {
    background: $unavailable;
  }
}

.drivers-container {
  margin-top: 5px;
  height: calc(100dvh - 440px);
  min-height: 600px;
  overflow: auto;
  overflow-x: hidden;
}
.list-drivers {
  background: $text;
  margin-left: -10px;
  height: 100%;
  overflow-y: scroll;
  display: initial;
  padding: 0;
}
.driver-item {
  margin-bottom: 10px;
  padding: 8px 36px 8px 20px;
  background: $formBackground !important;
  color: $text;
  cursor: pointer;
  position: inherit;
  border: 1px solid transparent;
  border-left: 7px solid;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;
  &.bookIn {
    border-left-color: $vancant;
  }
  &.offered,
  &.queue {
    border-left-color: $dispatch;
  }
  &.booked {
    border-left-color: $onTheWay;
  }
  &.arrived {
    border-left-color: $arrived;
  }
  &.engaged {
    border-left-color: $pob;
  }
  &.delivering {
    border-left-color: $pob;
  }
  &.droppedOff {
    border-left-color: $dropOff;
  }
  &.action {
    border-left-color: $waiting;
  }
  &.pre {
    border-left-color: $waiting;
  }
  &.bookOff {
    border-left-color: $unavailable;
  }
  &.collecting {
    border-left-color: $arrived;
  }
  &.otwMerchant {
    border-left-color: $onTheWay;
  }
  &:hover {
    &.bookIn {
      border-color: $vancant;
    }
    &.offered,
    &.queue {
      border-color: $dispatch;
    }
    &.booked {
      border-color: $onTheWay;
    }
    &.arrived {
      border-color: $arrived;
    }
    &.engaged {
      border-color: $pob;
    }
    &.delivering {
      border-color: $pob;
    }
    &.droppedOff {
      border-color: $dropOff;
    }
    &.action {
      border-color: $waiting;
    }
    &.pre {
      border-color: $waiting;
    }
    &.bookOff {
      border-color: $unavailable;
    }
    &.collecting {
      border-color: $arrived;
    }
    &.otwMerchant {
      border-color: $onTheWay;
    }
  }
}

.driver-action {
  position: absolute;
  right: 10px;
  top: 22px;
  font-size: 1.5em;
  i.fa.fa-pencil-square-o:hover {
    opacity: 0.5;
  }
  img {
    height: 20px;
  }
}

.fill.showcontrol {
  padding-right: 550px;
}
.form-inline div.form-group.auto-refresh-form {
  display: none;
}
.active .form-inline div.form-group.auto-refresh-form {
  display: block;
}
.auto-refresh-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 30px;
  .auto-refresh-icon {
    color: $menu-active-color;
    font-size: 21px;
  }
  .form-group.cc-checkbox-wrapper {
    margin-bottom: 0px;
    margin-right: 15px;
  }
}
.passenger-item {
  display: flex;
  width: 100%;
  .title {
    width: 20%;
    opacity: 0.3;
  }
  span:last-child {
    width: 80%;
  }
  img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
}

// responsive
@media only screen and (max-width: 1480px) {
  .map-zone-group {
    flex-direction: column;
    .select-map-zone-option {
      margin-left: 0 !important;
      margin-top: 5px;
    }
  }
}
@media only screen and (max-width: 1080px) {
}

@media only screen and (max-width: 768px) {
  .total-show {
    display: flex;
    flex-direction: column;
    .button-show-all {
      border-radius: 4px;
      background: $formBackground !important;
      height: 33px;
      padding: 8px 10px;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;
    }
    .total {
      width: 100%;
      padding-top: 7px;
    }
    .show-all {
      width: 100%;
      display: none;
    }
    .open-show-all {
      display: block;
    }
  }
  .map-select-zone {
    right: 0px;
    top: 110px !important;
    padding: 0 40px 0 20px;
    width: 100dvw !important;
    .map-zone-group {
      display: grid !important;
      grid-template-columns: 60% calc(40% - 10px) !important;
      width: 100%;
      gap: 10px;
      .select-map-zone-option {
        margin-top: 0 !important;
        width: unset !important;
      }
      .place-container {
        width: unset !important;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .chevron-right {
    left: -40px;
    .show-button {
      border-radius: 0 8px 8px 0;
      -webkit-border-radius: 0 8px 8px 0;
      -moz-border-radius: 0 8px 8px 0;
    }
  }
}

@media only screen and (max-width: 480px) {
  .chevron-right {
    left: -40px;
    .show-button {
      border-radius: 0 8px 8px 0;
      -webkit-border-radius: 0 8px 8px 0;
      -moz-border-radius: 0 8px 8px 0;
    }
  }
}

.map-zone-group {
  .autoplaces-input {
    position: relative;
  }
}

.map-autocomplete-place {
  position: relative;
}

.mapContainer {
  .map-controlers-container {
    padding-top: ($navbar-height + 40px);
  }

  .fill.showcontrol {
    padding-right: 530px;
    padding-top: 5px;
  }
}

/* HEATMAP */
.heatmapFilter {
  position: absolute;
  top: 0%;
  transform: translateY(50%);
  z-index: 1;
  padding: 15px 30px;
  width: 100%;

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 7px;
    position: absolute;
    top: 40px;
    right: 30px;
    left: 200px;
  }

  &__formItem {
    padding-right: 5px;
    padding-left: 5px;
  }

  .form-control[disabled],
  fieldset[disabled] .form-control {
    background: #252830;
  }

  .heatmapFilter_datetime .form-control[disabled] {
    background-color: #494e5a;
    border-color: #494e5a;
  }
}

@media only screen and (max-width: 768px) {
  .gmnoprint:not(.gm-style-mtc) {
    bottom: 24px !important;
    top: unset !important;
  }
  .gm-svpc {
    top: unset !important;
    bottom: 120px !important;
  }
  .gmnoprint:has(.gm-style-mtc) {
    top: unset !important;
    bottom: 0px !important;
    z-index: 1000001 !important;
    .gm-style-mtc {
      > ul {
        top: -40px !important;
      }
    }
  }
  .map-select-layer {
    top: unset;
    bottom: 65px;
    .dropdown-menu {
      top: -110px;
    }
  }
  .drivers-container {
    margin-top: 5px;
    height: calc(100dvh - 390px);
    min-height: 500px;
  }
  .heatmapFilter {
    padding: 15px 30px 15px 5px;
    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 7px;
      position:static;
    }

    &__formItem {
      padding-right: 5px;
      padding-left: 5px;
    }
    .form-control[disabled],
    fieldset[disabled] .form-control {
      background: #252830;
    }

    .heatmapFilter_datetime .form-control[disabled] {
      background-color: #494e5a;
      border-color: #494e5a;
    }
  }
  .child-1 {
    width: 55%;
    padding-right: 0px;
  }
  .child-2 {
    width: calc(45% - 7px);
    padding-left: 0px;
  }
  .child-3 {
    width: 40%;
    padding-right: 0px;
  }
  .child-4 {
    width: 40%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .child-5 {
    padding-left: 0px;
    width: calc(20% - 14px);
    .child-btn {
      padding: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      min-width: 100% !important;
      height: 34px;
    }
  }
}
