@import "../../assets/styles/variables.scss";

.cc-checkbox-wrapper{
  input.cc-checkbox {
    position: absolute;
    left: -9999px;
    &:not(:checked) {
      + label {
        .checked-icon {
          opacity: 0;
          transform: scale(0);
        }
      }
    }
    &:checked {
      + label {
        .checked-icon {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
    &:disabled {
      + label {
        box-shadow: none;
        cursor: not-allowed;
      }
    }
  }
  label {
    position: relative;
    padding-left: 27px;
    cursor: pointer;
    line-height: 18px;
    color: $text;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      padding-left: 1px;
      border-radius: 2px;
      background: $text;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
      outline: none;
      background: $formBackground;
    }
    &:after {
      display: none;
    }
    .checked-icon {
      position: absolute;
      top: 3px;
      left: 2px;
      /* font-size: 1.5em; */
      font-size: 14px;
      line-height: 0.9;
      color: $text-active-color;
    }
  }
  &.leftToRight {
    background-color: #2d313a;
    width: 100%;
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #262a30;
    margin-bottom: 0px !important;
    label {
      padding-left: 0px;
      width: 100%;
      display: block;
      &:before {
        left: auto;
        right: 0px;
      }
      .checked-icon {
        left: auto;
        right: -2px;
      }
    }
  }
  &.disabled {
    label {
      &:before {
        opacity: 0.5;
      }
      i {
        cursor: not-allowed;
      }
    }
  }
  &.tool-tip {
    display:inline;
  }
}