@import './../../assets/styles/variables.scss';

.qup-radio-wrapper {
  font-size: inherit;
  line-height: 1.5;
  color: inherit;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  margin-right: 8px;
  cursor: pointer;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;

  .qup-radio {
    font-size: inherit;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
    outline: none;
    display: inline-block;
    position: relative;
    vertical-align: sub;
    cursor: pointer;
    vertical-align: text-top;
  }

  .qup-radio-input {
    position: absolute;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .qup-radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 100px;
    background-color: $formBackground;
    transition: all 0.3s;
    .qup-radio-inner-select {
      position: absolute;
      width: 8px;
      height: 8px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 8px;
      display: none;
      border-top: 0;
      border-left: 0;
      content: ' ';
      background-color: $text-active-color;
    }
  }

  .qup-radio-text {
    padding-left: 8px;
    padding-right: 8px;
    color: $text;
  }

  &.checked {
    .qup-radio-inner-select {
      display: block;
    }
  }
}
