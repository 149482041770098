/*!
 * https://github.com/YouCanBookMe/react-datetime
 */
@import "../../assets/styles/variables.scss";

.rdt {
  position: relative;
  &.rdtShowOnTop.rdtOpen {
    .rdtPicker {
      top: unset;
      bottom: 0;
      margin-top: 0;
    }
  }
  .btn-clear-date {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    color: #a3a1a1;
    background-color: transparent;
    border: navajowhite;
  }
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: $formBackground !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;

  .rdtTime {
    .rdtCounters {
      display: flex;
      justify-content: center;
      align-items: center;
      > * {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    input {
      border-width: 1px;
      background-color: $page-content-background-color;
      border-color: transparent;
      &:focus {
        border-color: $text-active-color;
      }
    }
    .rdtCounter {
      .rdtBtn {
        &:hover {
          background: transparent;
          color: $text-active-color;
        }
      }
    }
  }

  .rdtTimeToggle {
    text-align: center;
  }
  table {
    width: 100%;
    margin: 0;
  }
  td,
  th {
    text-align: center;
    height: 28px;
  }
  td {
    cursor: pointer;
  }
  th {
    border-bottom: 1px solid #f9f9f9;
  }
  td.rdtYear:hover,
  td.rdtMonth:hover,
  td.rdtDay:hover,
  td.rdtHour:hover,
  td.rdtMinute:hover,
  td.rdtSecond:hover,
  .rdtTimeToggle:hover {
    cursor: pointer;
    color: $text-active-color;
    background: $menu-background !important
  }
  td.rdtOld,
  td.rdtNew {
    color: #999999;
  }
  td.rdtToday {
    position: relative;
    &:before {
      content: "";
      display: inline-block;
      border-left: 7px solid transparent;
      border-bottom: 7px solid #428bca;
      border-top-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      bottom: 4px;
      right: 4px;
    }
  }
  td.rdtActive,
  td.rdtActive:hover {
    background-color: #428bca;
    color: $app-base-color;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  td.rdtActive.rdtToday:before {
    border-bottom-color: $app-base-color;
  }
  td.rdtDisabled,
  td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  td {
    span.rdtOld {
      color: #999999;
    }
    span.rdtDisabled,
    span.rdtDisabled:hover {
      background: none;
      color: #999999;
      cursor: not-allowed;
    }
  }
  .dow {
    width: 14.2857%;
    border-bottom: none;
  }
  th.rdtSwitch {
    width: 100px;
  }
  th.rdtNext,
  th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
  }
  th.rdtDisabled,
  th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  thead tr:first-child th {
    cursor: pointer;
    &:hover {
      background: #eeeeee;
    }
  }
  tfoot {
    border-top: 1px solid #f9f9f9;
  }
  button {
    border: none;
    background: none;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }
  thead button {
    width: 100%;
    height: 100%;
  }
}

.rdtOpen .rdtPicker {
  display: block;
  width: 100%;
  border-radius: 4px;
  border:none;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
  &:hover {
    background: #eee;
  }
}
.rdtCounters {
  display: inline-block;
  > div {
    float: left;
  }
}

.rdtCounter {
  height: 100px;
  width: 40px;
  .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    &:hover {
      background: #eee;
    }
  }
  .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
  input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }
}

input.rdtCount-input {
  width: 100%;
  text-align: center;
}
