@import '../../../assets/styles/variables.scss';

/* general setting */
.actions {
  width: 110px;
  a {
    cursor: pointer;
    margin-right: 15px;
  }
  .fa-2x {
    font-size: 2em;
  }
}
.configBookType {
  display: flex;
  .form-group {
    margin-right: 20px;
  }
  &.affiliate {
    
  }
}
.affiliate {
  display: grid;
  grid-template-columns: auto auto auto;
}
.rush-date {
  width: 70px;
  display: inline-block;
}
.zone-map {
  height: 100%;
}
.noteZone {
  color: white;
  margin: 10px 0 20px 0;
}
.zone-location {
  width: 200px;
  position: absolute;
  right: 80px;
  top: 10px;
}
.zone-list {
  width: 100%;
  /* border: 1px solid #ccc; */
  height: 100%;
  overflow-y: auto;
  display: initial;
  display: block;
  height: calc(100vh - 200px);
  overflow-y: auto;
}
.table-hover > tbody > tr.zone-item:hover,
.table-hover > tbody > tr.zone-item.selected:hover,
.table-hover > tbody > tr.zone-item.selected {
  background: #c9ffb2;
}

tr.zone-item.selected, .tableZone tr.selected {
  background: #294945 !important;
}
.zone-left-side {
  width: 100%;
  position: relative;
  z-index: 101;
  height: 100%;
  margin: 0;
}
.carrier-name {
  padding: 10px 0 0 20px;
  font-weight: bold;
}
.currencies-tabs {
  padding: 0px;
  .nav.nav-tabs {
    margin-bottom: 0px;
    a {
      padding: 5px 15px;
    }
  }
  .tab-content {
    padding: 0 0px;
  }
}
.table-select-check {
  position: relative;
}

.model-thumbnail {
  max-height: 60px;
  max-width: 370px;
}

.add-model-container {
  display: block;
  border: 1px solid $menu-background;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.car-model-action {
  display: table-cell;
  .fa {
    font-size: 2em;
    margin: 5px 10px;
    cursor: pointer;
    &:hover {
      color: gray;
    }
  }
}
.image-container {
  position: absolute;
}
.model-image-remove {
  position: absolute;
  top: 0;
  right: 5px;
  color: #000;
  text-shadow: #ccc 1px 2px;
  font-size: 2.5em;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: red;
  }
}
table.model-table tbody {
  height: 100px; /* Just for the demo          */
  overflow-y: auto; /* Trigger vertical scroll    */
  overflow-x: hidden; /* Hide the horizontal scroll */
}
.assign-select {
  padding-right: 29px;
}
.assign-control {
  height: 100%;
}
.assign-container {
  position: relative;
}
.assign-ctr-contaner {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 2.5em;
}
.assign-ctr-contaner.compile {
  color: $text-active-color;
  i.fa {
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
}
select.select-auto-hieght {
  height: auto;
}
.compiled-list {
  height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: $formBackground;
  color: $app-base-color;
  border: 1px solid $formBackground;
  background-image: none;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  li {
    display: block;
    padding: 3px 0;
  }
}
select[multiple],
select[size] {
  height: 200px;
}
.operation {
  .small {
    height: 140px;
  }
  .large {
    height: 320px;
  }
  overflow-y: scroll;
}
.route-map {
  height: 200px;
  width: 100%;
}
.upload-btn-wrapper {
  color: $app-base-color;
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 20px auto;
  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.btn.upload {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}
.clickable {
  text-decoration: underline;
  cursor: pointer;
}
.disabled:hover {
  text-decoration: none;
}
.import-container {
  border: 1px dashed #daa520;
}
.app-banner-image {
  border: 2px dashed darkgoldenrod;
  width: 380px;
  height: 100px;
  margin: 10px auto;
  .btn {
    margin-top: 30px;
  }
  .thumbnail {
    width: 100%;
    height: 100px;
  }
}
.noborder.table > tbody > tr > td,
.noborder.table > tbody > tr > tr {
  border: none;
}
.route-name {
  width: 250px;
}
.break-line {
  display: inline-block;
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
}
.custom-form {
  .form-group.from-to {
    align-items: center;
    label.from-to-label {
      display: inline-block;
      min-width: 110px;
    }
  }
  .limit-group {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 30px;
    .limit-group-label {
      display: inline-block;
      min-width: 140px;
      min-height: 0px;
      padding-bottom: 0px;
    }
    .limit-group-selection {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
      min-width: 120px;
      input[type='radio'] {
        margin: 0px;
        margin-right: 5px;
      }
    }
  }
  .permission-form {
    .form-group {
      min-height: 0px;
    }
    .form-group.select-permission-type-group {
      justify-content: flex-start;
      align-items: center;
      .fa-question-circle {
        margin-right: 50px;
      }
    }
  }
}

.fit-content-modal {
  max-width: 1500px !important;
  i.fa.fa-question-circle {
    margin-right: 45px;
  }
  .cpn {
    i.fa.fa-question-circle {
      margin-right: 5px;
    }
  }
}

.referral-form {
  .default-font-size {
    font-size: 16px;
  }
  .radio-button-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    label.control-label {
      &:first-child {
        width: 150px;
        margin-bottom: 0px;
      }
    }
    label.qup-radio-wrapper {
      width: 155px;
    }
  }
  .driver-to-pax-form {
    // max-width: 800px;
    width: 60%;
  }
}


.event-detail-form {
  .default-font-size {
    font-size: 16px;
  }
  .radio-button-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    label.control-label {
      &:first-child {
        width: 150px;
        margin-bottom: 0px;
      }
    }
    label.qup-radio-wrapper {
      width: 155px;
    }
  }

}

.general-setting {
  label.title,
  .title {
    color: $sectionHeaderColor;
    text-transform: lowercase;
    font-size: 16px;
    &::first-letter {
      text-transform: uppercase !important;
    }
  }

  ul.nav.nav-tabs {
    margin-bottom: 2px;
  }

  .radio-group {
    display: flex;
  }
  .control-label {
    .require {
      margin-left: 5px;
    }
  }
  .custom-form {
    .control-label {
      align-items: center !important;
    }
  }

  .additional-service-table-container {
    width: 100%;
    overflow: auto;
    table {
      tr {
        td {
          word-break: unset;
          min-width: 100px;
          &:nth-child(1) {
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
          }
          &:nth-child(5) {
            text-align: right;
          }
        }
      }
    }
  }
  .form-group {
    margin-bottom: $commonMargin;
    &.form-group-title {
      padding: 0;
    }
  }
  .form-group-title {
    padding: 0;
    margin-bottom: $commonMargin;
  }

  .default-top-up {
    &__requiredfieldstext {
      color: $app-base-color;
    }
  }

  .form-group.vehicle_age_limit {
    display: flex;
    align-items: center;
  }

}

.qup-input-group .input-group.vehicle_age_limit {
  width: 150px;
  padding-left: 20px
}

// App Banner
.app-banner-wrapper {
  .app-banner-form-container {
    max-width: 1300px;
  }
  .form-group {
    margin-bottom: 0px;
    width: 260px;
    &.apply-to-control {
      max-width: 380px;
      width: auto;
    }
  }
  .bottom-container {
    width: auto;
  }
  .app-banner-upload-image {
    border: 2px dashed $text-active-color;
    width: 380px;
    height: 100px;
    margin: 10px 0;
  }
}
.form-custom.app-banner-multi-select {
  height: 150px;
}


.car-model-upload-image {
  width: 100%;
  height: 100px;
  border: 2px dashed $text-active-color;
}

.event-upload-image {
  width: 100%;
  height: 100px;
  border: 2px dashed $text-active-color;
}

select.none-bg-arrow {
  background-image: none !important;
}

.none-bg-arrow {
  .Select-arrow-zone {
    display: none;
  }
}

.seting-voice-sms,
.setting-dispatch {
  overflow-x: hidden;
  overflow-y: scroll;
  .form-group-title {
    padding: 0;
    margin-bottom: $commonMargin;
  }
}

.voice-sms-content{
  padding: 20px 10px 10px;
  background: $page-content-background-color;
  height: 100%;
}

.voice-sms-content .voip-item {
  padding-left: 20px;
}

.voice-sms-content .save-voip {
  padding-top: 20px
}

.Select--multi.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background: $disableInputBackgroundColor;
  border-color: $disableInputBorderColor;
  border-radius: 4px;
  .Select-value,
  .Select-control {
    background-color: $disableInputBackgroundColor !important;
    border-color: $disableInputBorderColor;
  }
}

.rdt.form-custom.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background: $disableInputBackgroundColor;
  border-color: $disableInputBorderColor;
  border-radius: 4px;
}

.operation-zone-shift {
  .form-group {
    &.mb {
      margin-bottom: 10px;
    }
  }
  select {
    max-height: 140px;
  }
}

.queu-area-modal {
  select[multiple] {
    background-image: none !important;
  }
}

#cartype-dropdown.text-active {
  &.cc-drop-down {
    button {
      .dropdown-title {
        color: $text-active-color;
      }
    }
  }
}

.specific-permission-modal {
  .modal-dialog {
    max-width: 1200px;
  }
  .cc-drop-down.open {
    .btn.dropdown-toggle,
    .dropdown-menu {
      background-color: $dropdown-bg;
    }
  }
  .add-item-to-permitted {
    color: $text-active-color;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -40px;
    i {
      font-size: 30px;
    }
  }
}

.permission-detail {
  .permission-form {
    .row {
      display: flex;
      flex-flow: row wrap;
    }
  }
  .group-roles {
    background-color: $table-row-hightlighted-bg;
    padding: 20px;
    border-radius: 8px;
    display: flow-root;
  }
}

.zone-active {
  color: $menu-active-color;
  font-size: large;
  .fa {
    color: #ffffff;
  }
}

.rate-modal {
  .text-soft-warning {
    margin: 0;
    padding-top: 5px;
  }
}

.form-group-title {
  text-transform: none;
}

.scheduleTimes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  &__time {
    display: flex;
  }
}

.routeSettingTitle {
  display: flex;
}

.intercityRateModal{
  max-width: 1000px;
}

.intercityRoute__timeList {
  max-height: 260px;
  overflow-y: auto;
  padding-right: 15px;
}

.scheduleEnable {
  display: flex; 
}

.scheduleEnable.keepMinFee {
  span {
    flex: 1 1;
  }
  .form-group.cc-checkbox-wrapper.ml.mb0 {
    flex: 2 1;
    margin-left: -5px;
  }
}

.requestTimeEnable {
  display: flex; 
}

.hideRouteEnable {
  display: flex; 
}


.intercityRoute__workingTime {
  display: flex; 
}

.pax-to-pax-form {
  width: 60%;
  .passenger-field-item {
    padding: 10px 15px 10px;
  }
  .passenger-note-item {
    padding: 0px 15px 5px;
  }
}

// Regular rate
.price_adjustment {
  label {
    color: $sectionHeaderColor;
    text-transform: lowercase;
  }
}
.qup-radio-wrapper {
  display: inline-table;
}

.stripe_status.empty {
  color: #DE6C59;
}
.stripe_status.pending {
  color: #ff9900;
}
.stripe_status.activated {
  color: #04BE76;
}
.companyStripe {
  .control-label span {
    display: block;
    color: #04BE76;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .strStatus {
    font-size: 20px;
    .stripe_status.empty {
      color: #DE6C59;
    }
    .stripe_status.pending {
      color: #ff9900;
    }
    .stripe_status.activated {
      color: #04BE76;
    }
  }
}
.contentImport {
  padding-top: 30px;
  max-height: 500px;
  overflow: auto;
}
.group-general.intercityZone {
  .zoneList {
    max-height: 500px;
    overflow: auto;
  }
}