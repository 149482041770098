@import './variables.scss';
// .app-wrapper {

// format form
.control-label,
.form-label,
.text {
  color: $text;
}
.invisible-control-label {
  visibility: hidden;
}
.form-custom,
.form-custom-addon,
.form-custom-no-margin,
.intl-tel-input input,
.rdt .form-control,
.receiver-list .Select-control {
  box-shadow: none;
  background: $formBackground;
  color: $text;
  border: 1px solid transparent;
  &:focus {
    border: 1px solid $headerTitleTable;
    box-shadow: none;
  }
  > input {
    color: white;
  }
}

.form-control {
  box-shadow: none;
}

input.form-control.form-custom {
  box-shadow: none;
  &:disabled {
    background-color: $disableInputBackgroundColor;
    border-color: $disableInputBorderColor;
  }
  &.disabled {
    background-color: $disableInputBackgroundColor;
    border-color: $disableInputBorderColor;
  }
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background: $disableInputBackgroundColor;
  border-color: $disableInputBorderColor;
}
.message-item {
  margin-top: -12px;
}

.form-detail-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &:before,
  &:after {
    display: none;
  }
}
.form-detail .form-detail-inner,
.form-detail-md .form-detail-inner {
  .form-group {
    width: calc((100% - 30px) / 2);
  }
}
.form-detail {
  input.cc-checkbox[type='checkbox']:not(:checked) + label:after,
  input.cc-checkbox[type='checkbox']:checked + label:after {
    color: $headerTitleTable;
  }
}
.text-require-page {
  margin-top: 20px;
  color: $text;
}
.addon-inner {
  position: relative;
  .addon-icon {
    position: absolute;
    bottom: 7px;
    right: 12px;
    color: $text;
  }
  input {
    padding-right: 30px;
  }
}

.group-addon {
  width: 100% !important;
}
//placeholder
.form-control::-webkit-input-placeholder {
  color: $text;
  opacity: 0.3;
}
// phone
.intl-tel-input {
  .country-list {
    background-color: $formBackground !important;
    color: $text;
    width: 100%;
    border-color: $headerTitleTable;
    border-radius: 4px;
    z-index: 999;
    overflow-y: auto;
    .country {
      font-size: 13px;
      &:hover {
        span {
          color: $headerTitleTable !important;
        }
      }
    }
    max-height: 300px;
  }
  .selected-flag {
    .iti-arrow {
      border-top: 4px solid $headerTitleTable;
      &.up {
        border-bottom: 4px solid $headerTitleTable;
      }
    }
  }
}

// select
select.form-custom.form-control,
select.form-custom-no-margin.form-control {
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      $headerTitleTable 50%
    ),
    linear-gradient(135deg, $headerTitleTable 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 6px, 5px 6px, 1px 1.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  &:disabled {
    background-image: linear-gradient(
        45deg,
        transparent 50%,
        $formBackground 50%
      ),
      linear-gradient(135deg, $formBackground 50%, transparent 50%);
  }
}

select.form-custom.form-control,
select.form-control {
  padding-right: 35px;
  box-shadow: none;
}

select[multiple] {
  &.form-control {
    background-image: none;
  }
}

select {
  option {
    max-width: 100%;
    padding: 5px 0;
    &:checked {
      background-color: $text-active-color;
    }
    &:selected {
      background-color: $text-active-color;
    }
  }
  &:-webkit-autofill {
    option {
      max-width: 100%;
      &:checked {
        background-color: $text-active-color;
      }
    }
  }
}
// search
.search-format-map {
  width: 100%;
}
.search-format {
  margin-right: 4vw;
}
.search-format,
.search-format-map,
.search-format-cus {
  position: relative;
  min-width: 230px;
  float: left;
  .search-form,
  .search-form-map {
    border: 1px solid transparent;
    padding: 6px 12px 6px 30px;
    color: $text;
    &:focus {
      border: 1px solid $headerTitleTable;
      box-shadow: none;
    }
  }

  .search-form {
    background: $modalBodyBgr;
    &.form-control {
      &:disabled {
        background: $modalBodyBgr;
        opacity: 1;
      }
    }
  }
  .search-form-map {
    background: $formBackground !important;
  }
  .search-icon {
    color: $app-base-color;
    opacity: 0.3;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .spinner-icon {
    color: $app-base-color;
    opacity: 0.3;
    font-size: 20px;
    top: 7px;
    left: 6px;
    position: absolute;
  }

  &.search-by {
    display: flex;
    background: $modalBodyBgr;
    border-radius: 4px;
    width: 280px;
    justify-content: space-between;
    .input-container {
      flex: 1 1 auto;
    }
    .cc-drop-down.search-by-dropdown {
      position: relative;
      .btn.dropdown-toggle {
        padding: 5px 20px 5px 12px;
        height: 100%;
        margin: 0;
        background: $modalBodyBgr;
        min-width: unset;
        border-bottom-right-radius: 4px;
        max-width: 140px;
        .caret {
          right: 8px;
        }
      }
      .dropdown-menu {
        background: $dropdown-bg;
        max-width: unset;
        width: 280px;
        .menu-container ul a {
          padding-left: 5px;
        }
      }
      &.show {
        .btn.dropdown-toggle {
          background: $dropdown-bg;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}
// radio button
.radio-inner {
  position: relative;
  display: inline-block;
  input[type='radio'] {
    position: absolute;
    visibility: hidden;
  }
  .radio-text {
    position: relative;
    padding: 30px 25px 25px 25px;
    height: 10px;
    z-index: 9;
    cursor: pointer;
    color: $text;
    display: inline-block;
  }
  .radio-check {
    display: block;
    position: absolute;
    border: 1px solid $border;
    border-radius: 100%;
    height: 20px;
    background: $formBackground !important;
    width: 20px;
    top: 30px;
    left: 0;
    z-index: 5;
    &::before {
      position: absolute;
      content: '';
      border-radius: 100%;
      height: 45%;
      width: 45%;
      top: 27%;
      left: 28%;
      transition: background 0.25s linear;
      -webkit-transition: background 0.25s linear;
    }
  }
  &:hover .radio-check {
    border: 1px solid $text;
  }
}
input[type='radio']:checked ~ .radio-check {
  border: 1px solid $headerTitleTable;
  &::before {
    background: $headerTitleTable;
  }
}
// popup
.modal-header {
  background: $modalHeadBgr;
  border-bottom: none;
}
.modal-title {
  color: $headerTitleTable;
  line-height: initial;
  // text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
  width: 100%;
}
.modal-body,
.modal-footer {
  background: $modalBodyBgr;
}
.modal-footer {
  border-top: none;
}
.modal-max-600 {
  max-width: 600px !important;
}
.form-detail,
.form-detail-md {
  .form-custom,
  .intl-tel-input,
  .form-addon {
    margin: 6px 0 12px;
  }
  .rdt .form-control {
    margin-top: 6px;
  }
}
.form-detail {
  padding: 30px;
}
.form-detail-md {
  padding: 15px;
}

// button bottom
.btn {
  padding: 8px 30px;
  border: none;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}
.dropdown {
  .btn {
    padding: 8px;
  }
}
.btn-save,
.btn-send,
.btn-edit {
  background: $positiveBtnBgr;
  color: $text;
  &:disabled,
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background: $positiveBtnBgr !important;
    color: $text;
    outline: none;
  }
  &.lightMode {
    color: $text !important;
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: $text !important;
    }
  }
}
.btn-save,
.btn-send,
.btn-edit,
.btn-reset,
.btn-cancel {
  min-width: 100px;
}
.btn-link, a {
  color: #337ab7;
}
.btn,
.btn-link {
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn-clone {
  background: $cloneBtn;
  color: $text !important;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background: $cloneBtn !important;
    color: $text !important;
  }
}

.btn-cancel {
  background: $cancelBtn;
  color: $text !important;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background: $cancelBtn !important;
    color: $text !important;
  }
  &.lightMode {
    color: $text !important;
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: $text !important;
    }
  }
}

.btn-unassign {
  background: #ff3a3a;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background: #ff3a3a !important;
    color: $text !important;
  }
  &.lightMode {
    color: $text !important;
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: $text !important;
    }
  }
}

.btn-reset {
  background: $completeBtn !important;
  color: $text !important;
  padding: 6px 15px;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background: $completeBtn !important;
    color: $text !important;
  }
  &.lightMode {
    color: $text !important;
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: $text !important;
    }
  }
}

.btn-edit-only {
  background-color: #de6c59;
  color: white;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background: #de6c59 !important;
    color: white !important;
  }
}

.btn-edit-all {
  background-color: #63666f;
  color: white;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background: #63666f !important;
    color: white !important;
  }
}

.btn-now {
  position: absolute;
  right: 10px;
  top: 4px;
  padding: 2px 9px;
  border: none;
  // border-right: 1px solid $headerTitleTable;
  color: $headerTitleTable;
  background-color: transparent;
  z-index: 3;
  /* border-color: #d43f3a; */
}
.btn-bottom-right {
  padding-right: 50px;
}
// button header
.btn-header {
  background-color: transparent;
  padding-left: 6px;
  padding-right: 6px;
  border: none;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: transparent;
    outline: none;
    border: none;
  }
  &:disabled {
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background-color: transparent;
      outline: none;
      border: none;
      cursor: not-allowed;
    }
  }
  > * {
    vertical-align: middle;
  }
}
.btn.text-active {
  color: $text-active-color;
}
.text-active {
  color: $text-active-color;
}
.dropdown-toggle-hide-arrow::after {
  display: none !important;
}
.btn-link {
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    text-decoration: none;
  }
}
.text-add-header,
.text-active-header {
  color: $headerTitleTable !important;
  &:not(.no-hover) {
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: $headerTitleTable;
      opacity: $btnHeadernHoverOpacity;
    }
  }
}
.text-deactive-header,
.text-delete-header,
.text-cancel-header {
  color: $negativeBtnBgr !important;
  &:not(.no-hover) {
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: $negativeBtnBgr;
      opacity: $btnHeadernHoverOpacity;
    }
  }
}
.icon-set-size {
  width: 16px;
  margin-right: 5px;
}
// calendar
.rdtPicker {
  color: $text;
  background: $formBackground !important;
}
th.rdtSwitch:hover,
.rdtNext span:hover,
.rdtPrev span:hover {
  color: $headerTitleTable;
  background: $formBackground !important;
}
.calendar-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 3;
  &:hover {
    cursor: pointer;
  }
}

.Select-arrow-zone {
  width: 33px;
}
.Select-arrow {
  border-color: $headerTitleTable transparent transparent;
  border-width: 5px 4.5px 2.5px;
}
// }

//end popup background
// }

::-webkit-scrollbar {
  width: $scroll-width;
  height: $scroll-height;
}

::-webkit-scrollbar-corner {
  background-color: $scroll-track;
}

::-webkit-scrollbar-track {
  background-color: $scroll-track;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: $scroll-thumb;
  border-radius: 10px;
  border: 1px solid $scroll-track;
}

select::-webkit-scrollbar {
  width: $scroll-width;
  height: $scroll-height;
}

select::-webkit-scrollbar-corner {
  background-color: $scroll-track;
}

select::-webkit-scrollbar-track {
  background-color: $scroll-track;
  border-radius: 10px;
}

select::-webkit-scrollbar-thumb {
  background-color: $scroll-thumb;
  border-radius: 10px;
  border: 1px solid $scroll-track;
}
.table-actions {
  // background-color: $dropdown-bg;
  .public_fixedDataTableCell_wrap3 {
    padding: 0;
  }
  .public_fixedDataTableCell_cellContent {
    padding: 0;
  }
}

:focus {
  outline: none;
}
//checkbox

.form-group.checkbox-margin {
  margin-bottom: 10px;
}
.header-button-group {
  justify-content: space-between;
  flex-flow: row wrap;
}

.header-btn-group-start {
  button:nth-child(3) {
    margin: 0 80px 0 40px;
  }
  button:nth-child(4) {
    margin-right: 40px;
  }
}
.header-button-group,
.header-btn-group-start {
  .search-format,
  .search-format.form-group {
    margin-bottom: 0px;
  }

  .toolbar-search-input {
    background: $modalBodyBgr;
    &.form-control {
      &:disabled {
        background: $modalBodyBgr;
        opacity: 1;
      }
    }
  }

  .form-group {
    margin-bottom: 0;
  }
  .head-first-part,
  .head-second-part {
    display: flex;
    flex-wrap: wrap;
  }
  margin-bottom: 10px;
  padding: 15px;
  background: $menu-background;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  align-items: center;
  &:before,
  &:after {
    display: none;
  }
  .btn-group {
    margin: 0;
    .btn-header {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        right: -10px;
        top: 0;
        height: 100%;
        border-left: 1px solid #363a44;
      }
      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }
  .group-left {
    .form-group {
      margin-bottom: 0px;
    }
    > * {
      float: left;
      margin-right: 20px !important;
      &:last-child {
        margin-right: 0 !important;
      }
    }
    @media only screen and (max-width: 767px) {
      &.groupMB {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .customeMb {
          width: 48% !important;
          min-width: unset;
          margin-right: 0px !important;
          margin: 10px 0;
        }
        .customeMb_dateRange {
          width: auto;
          max-width: 40%;
          min-width: unset;
          margin-right: 0px !important;
          #date-range-dropdown {
            padding-left: 0px;
          }
        }
        .customeMb_search {
          flex: 1;
          margin-left: 1%;
          min-width: unset;
          margin-right: 0px !important;
        }
      }
    }
    @media only screen and (max-width: 992px) {
      &.groupMB {
        > div {
          margin: 5px 0;
        }
      }
    }
  }
  .group-right {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;
    > * {
      margin-left: 20px !important;
    }
    .form-group {
      margin-bottom: 0px;
    }
    @media only screen and (max-width: 767px) {
      > * {
        margin-left: 0px !important;
      }
    }
  }
  .cc-drop-down {
    .btn.dropdown-toggle {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: transform .3s ease-out;
  }
}
.tooltip-inner {
  max-width: 400px;
}
.add-on-input-group {
  position: relative;
  input.form-control {
    height: 34px;
    padding-right: 50px;
  }
  .add-on-button {
    position: absolute;
    right: 10px;
    top: 0;
    line-height: 34px;
    height: 34px;
    color: $recentButtonColor;
    cursor: pointer;
    z-index: 7;
    font-size: 13px;
    margin: 0px;
    padding: 0px;
  }
}

.rdtPicker th {
  border-bottom: 0.5px solid $modalBodyBgr;
}

.custom-select-control.car-color-suggest {
  border-radius: 5px;
  height: 34px;
  .Select-control {
    height: 34px;
  }
}
.custom-select-control .car-color-suggest-note {
  border-radius: 5px;
  height: 200px;
  overflow: scroll;
  .Select-control {
    height: 34px;
  }
}
.custom-select-control.email-notification {
  border-radius: 5px;
  padding-bottom: 10px !important;
  font-size: 16px;
  color: white !important;
}
.custom-select-control.email-notification.general-select {
  height: 100% !important; 
}
.custom-select-control {
  .Select-control {
    background-color: $formBackground;
    border-color: transparent;
    .Select-value {
      padding-left: 12px;
      padding-right: 12px;
      margin-top: 3px;
      margin-bottom: 3px;
      .Select-value-label {
        color: $app-base-color !important;
      }
    }
  }
  &.is-open,
  &.is-focused:not(.is-open) {
    > .Select-control {
      background-color: $formBackground;
      border-color: transparent;
      box-shadow: none;
      .Select-arrow {
        border-width: 6px 5px 0 6px;
        border-color: $text-active-color transparent transparent;
        top: 0;
      }
    }
  }
  &.is-disabled {
    > .Select-control {
      cursor: not-allowed;
      // opacity: 0.5;
      background: $disableInputBackgroundColor;
      border-color: $disableInputBorderColor;
      .Select-arrow-zone {
        opacity: 1;
      }
      .Select-arrow {
        border-color: $menu-background transparent transparent;
      }
    }
  }
  &.is-focused {
    .Select-control {
      background-color: $formBackground;
      border-color: transparent;
    }
  }
  .Select-menu-outer {
    background-color: $formBackground;
    border: 1px solid $formBackground;
    border-top-color: $formBackground;
  }
  .Select-arrow {
    border-width: 6px 5px 0 6px;
  }
  .Select-option {
    box-sizing: border-box;
    background-color: $formBackground;
    color: $app-base-color;
    cursor: pointer;
    display: block;
    padding: 6px 12px;
    &:last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:hover {
      background-color: $page-content-background-color;
    }
  }
}

.Select-input > input {
  color: white;
}

.modal {
  &.fade {
    transform: none;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity var(--transition-speed) ease,
    visibility var(--transition-speed) ease;
  }
}

.modal-dialog {
  width: 80%;
  max-width: 1000px;
  .modal-body {
    padding: 20px;
    .horizontal-line {
      border-top: 1px solid $formBackground;
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }
  .modal-footer {
    padding: 0 20px 20px;
    justify-content: center;
    button.btn {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
    .btn + .btn {
      margin-left: 20px !important;
    }
  }
  .modal-header {
    padding: 20px;
    text-align: center;
    button {
      &.close {
        text-indent: -9999px;
      }
      width: 20px;
      height: 20px;
      background-size: 16px auto;
      background-position: center center;
      background-repeat: no-repeat;
      right: 20px;
      top: 22px;
    }
  }
  .modal-content {
    border-radius: 8px;
    background-color: $page-content-background-color;
    overflow: hidden;
    box-shadow: 0 5px 15px #00000080;
  }
  &.booking-map-modal {
    margin-top: 5vh;
    width: 80vw;
    height: 90vh;
    max-width: unset;
    max-height: unset;
    .modal-content {
      height: 100%;
      background-color: $page-content-background-color;
      .modal-body {
        height: -webkit-calc(100% - 85px);
        height: -moz-calc(100% - 85px);
        height: calc(100% - 85px);
        padding: 15px;
      }
    }
  }
}

.nav-tabs {
    padding: 0;
    border: none;
      .nav-item {
        padding: 0 20px 15px 20px;
        color: $app-base-color;
        background-color: transparent !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        outline: none !important;
        box-shadow: none !important;
        margin: 0;
        cursor: pointer;
        font-size: 14px;
        font-weight: unset;
        border-bottom-width: 2px !important;
        border-bottom-style: solid !important;
        border-bottom-color: transparent;
        &:hover,
        &:focus,
        &:active,
        &:active:focus {
          background-color: transparent !important;
          border-top: none !important;
          border-left: none !important;
          border-right: none !important;
          border-bottom-width: 2px;
          border-bottom-style: solid;
          border-bottom-color: transparent;
          outline: none;
          box-shadow: none;
          color: $text-active-color;
        }
        &.active,
        &.active:hover,
        &.active:focus {
            color: $text-active-color;
            font-weight: bold;
            border-bottom-color: $text-active-color;
          }
        }
  }


.list-group-item {
  background-color: transparent;
  padding: 0;
  border: none;
}
.error.form-group {
  .qup-input-group {
    :focus-within {
      .input-group-prepend, .input-group-append {
        border-color: transparent;
      }
    } 
  }
}
.qup-input-group {
  .form-control {
    transition: unset;
  }
  :focus-within {
    .input-group-prepend, .input-group-append {
      border-color: $text-active-color;
    }
  } 
  .input-group {
    color: $app-base-color;
    width: 100%;
    // background-color: $formBackground;
    border-radius: $common-border-radius;
    &.disabled {
      cursor: not-allowed;
      background-color: $disableInputBackgroundColor;
      border-color: $disableInputBorderColor;
    }
    * {
      background-color: transparent;
      border: 1px solid $formBackground;
      color: inherit;
      &.form-custom {
        background-color: $formBackground;
      }
    }
    .input-group-text {
      background-color: $menu-background;
      padding: 0.3rem 0.75rem;
      * {
        border: none;
      }
    }
    input {
      height: unset;
      border-radius: 0;
      box-shadow: none;
      transition: unset;
      padding-left: 8px;
      padding-right: 8px;
      background-color: $menu-background;
      & + .input-group-prepend {
        background-color: $menu-background;
      }
      &:focus {
        box-shadow: none;
        outline: none;
        border: 1px solid $text-active-color;
        border-left: none;
        border-right: none;
      }
      &:disabled {
        border-color: $disableInputBorderColor;
        border-left: none;
        border-right: none;
        background-color: transparent;
        & + .input-group-prepend {
          background-color: transparent;
          .input-group-text {
            border-color: $disableInputBorderColor;
            background-color: transparent;
          }
        }
        ~ .input-group-prepend,
        ~ .form-control {
          border-color: $disableInputBorderColor;
          background-color: transparent;
          .input-group-text {
            border-color: $disableInputBorderColor;
            background-color: transparent;
            border: unset;
          }
        }
      }
    }
    .input-group-append {
      border-top-right-radius: $common-border-radius;
      border-bottom-right-radius: $common-border-radius;
      height: 35px;
    }
    .input-group-prepend {
      border-top-left-radius: $common-border-radius;
      border-bottom-left-radius: $common-border-radius;
      height: 35px;
    }
    &.single-addon-right {
      input {
        padding-left: 12px;
        border-top-left-radius: $common-border-radius;
        border-bottom-left-radius: $common-border-radius;
        order: 0;
        flex: none;
        flex-grow: 1;
        &:focus {
          border-left: 1px solid $text-active-color;
          ~ .input-group-append, ~ select {
            border-color: $text-active-color;
          }
        }
        &:disabled {
          border-left: 1px solid $disableInputBorderColor;
        }
      }
      select {
        width: auto;
        flex-grow: 0;
      }
      .input-group-append select {
        border: none;
        height: calc(1.5em + 0.75rem);
      }
    }
    &.single-addon-left {
      input {
        order: 1;
        padding-right: 12px;
        border-top-right-radius: $common-border-radius !important;
        border-bottom-right-radius: $common-border-radius !important;
        border-right: 1px solid transparent;
        &:focus {
          border-color: $text-active-color;
          & + .input-group-addon,
          & + .input-group-prepend,
          & + .form-control {
            border-color: $text-active-color;
          }
          ~ select {
            border-color: $text-active-color;
          }
        }
        &:disabled {
          border-right: 1px solid $disableInputBorderColor;
        }
      }
      select {
        margin-left: 0px;
        order: 0;
        border-right: none;
        border-top-right-radius: unset;
        border-top-left-radius: $common-border-radius;
        border-bottom-right-radius: unset;
        border-bottom-left-radius: $common-border-radius;
        flex-grow: inherit;
        width: auto;
      }
    }
  }
}

.select-addon-control {
  padding: 0;
  border: 0;
  .select-addon {
    width: auto;
    height: 32px;
    background-color: $menu-background;
    border-radius: 4px !important;
    color: $text-active-color;
    &:disabled {
      background-color: transparent;
      border-color: $disableInputBorderColor;
    }
  }
}

.cc-drop-down {
  &.column-filter {
    margin-right: -10px;
    button.dropdown-toggle {
      margin-top: 0;
      padding: 8px;
      min-width: unset;
      .caret {
        display: none;
      }
    }
    .dropdown-menu {
      background-color: $columnFilterDropdownBg;
      padding-top: 10px;
      max-width: unset;
      .menu-container {
        max-width: unset;
        max-height: 500px;
        width: 380px;
        ul {
          display: flex;
          flex-flow: row wrap;
          a {
            width: 50%;
            white-space: normal;
            word-break: break-word;
            .cc-checkbox-wrapper {
              label {
                display: inline-block;
                &:before {
                  background: transparent;
                }
              }
            }
          }
        }
        @media only screen and (max-width: 767px) {
          width: 270px;
          .collapse-filter-title {
            display: block !important;
          }
          ul {
            flex-wrap: wrap;
            a {
              width: 100%;
            }
          }
        }
      }
    }
    &.show {
      .btn.dropdown-toggle {
        background-color: $columnFilterDropdownBg;
      }
    }
  }
  span.dropdown-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 250px;
  }

  &.ccDropdown-header-group {
    .dropdown-menu {
      background-color: $columnFilterDropdownBg;
    }
    &.show {
      button.dropdown-toggle {
        background-color: $columnFilterDropdownBg;
      }
    }
  }
}

.Active a,
.Active {
  color: $text-active-color !important;
  &:hover {
    opacity: 0.5;
  }
}

.Inactive a,
.Inactive {
  color: $showError !important;
  &:hover {
    opacity: 0.5;
  }
}

.form-detail-container {
  .form-group {
    margin-bottom: 20px;
  }
}

/* Overwirte color chomre auto fill input */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid $page-content-background-color;
  -webkit-text-fill-color: $text;
  transition: $formBackground 5000s ease-in-out 0s;
  box-shadow: 0 0 0px 1000px $formBackground inset !important;
}

/*custom size for Modal type = confirm*/
.confirm {
  .modal-dialog {
    max-width: 650px;
    .modal-header,
    .modal-body,
    .modal-footer {
      padding: 20px;
    }
    .modal-footer {
      padding-top: 0px;
    }
    .modal-header {
      .close {
        top: 22px;
      }
    }
  }
}

.compiled-list {
  background-color: $formBackground;
  color: $app-base-color;
  border: 1px solid $formBackground;
  margin: 0;
}
.has-error, .error {
  .form-label {
    color: $hasShowError ;
  }
  .invalid-feedback {
    display: block;
    color: $hasShowError;
    font-size: 14px;
  }
  .form-control {
    box-shadow: none !important;
  }
  .form-control {
    border: 1px solid #a94442 !important;
  }
  .form-control.form-custom-addon {
    border: unset !important;
  }
  
  .input-group {
    .form-control {
      border: 1px solid #a94442 !important;
      border-left: none !important;
      border-right: none !important;
    }
  }

  .input-group.single-addon-right {
    input {
      border: 1px solid #a94442 !important;
      border-right: none !important;
    }
    .form-control.input-group-addon-custom {
      border: 1px solid #a94442 !important;
      border-left: none !important;
    }
  }
  .input-group.single-addon-left {
    input {
      border: 1px solid #a94442 !important;
      border-left: none !important;
    }
    .form-control.input-group-addon-custom {
      border: 1px solid #a94442 !important;
      border-right: none !important;
    }
  }
  .input-group-prepend {
    border: 1px solid #a94442 !important;
  }
  .input-group-append {
    border: 1px solid #a94442 !important;
  }
}
.form-detail-container {
  .row {
    display: flex;
    flex-flow: row wrap;
    &:before,
    &:after {
      display: none;
    }
  }
}
.assign-select {
  padding-right: 29px;
}
.fit-content-modal {
  max-width: 1500px !important;
  i.fa.fa-question-circle {
    margin-right: 45px;
  }
  .cpn {
    i.fa.fa-question-circle {
      margin-right: 5px;
    }
  }
}

input[type='number'],
input.number-no-spin[type='number'],
input.number-no-spin[type='number'] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

a:focus {
  outline: none;
}

table.table-no-pagination,
table.cc-table {
  background-color: $table-no-pagination-bg;
  color: $text;
  border-radius: 0;
  &.large-padding {
    tr {
      th,
      td {
        padding: 15px;
      }
    }
  }
  .table-header,
  thead {
    background-color: $menu-background;
    color: $text-active-color;
    height: 100%;
    width: 100%;
    span {
      margin-right: 10px;
      font-weight: bold;
    }
    i {
      margin: 0;
      color: $app-base-color;
    }
  }
  tr {
    border-bottom: 1px solid $table-no-pagination-row-border;
    .fa {
      font-size: 1.5em;
      &.fa-trash {
        color: $negativeBtnBgr;
      }
      &.fa-edit {
        color: $iconColor;
      }
    }
    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }

  &.cc-table-striped {
    tbody {
      tr {
        &:hover {
          background-color: $table-row-hover-bg;
        }
        border: none;
        &:nth-child(even) {
          background-color: $page-content-background-color;
          &:hover {
            background-color: $table-row-hover-bg;
          }
        }
      }
    }
  }

.table th, .table td, .table thead th {
    border-top: none !important;
}

  .table-actions-dropdown {
    button.dropdown-toggle {
      width: auto;
      color: $text-active-color !important;
      padding: 8px 10px;
      background-color: transparent;
      border: none !important;
      box-shadow: none !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      outline: none;
      min-width: unset;
      > i.fa-reorder {
        margin: 0;
      }
      .caret {
        border-top: 4px dashed;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        border-bottom: none;
        display: none;
      }
      &:after {
        display: none;
      }
    }
    &.show {
      .dropdown-toggle {
        background-color: $dropdown-bg;
      }
    }
    div.actions {
      left: unset;
      right: 0;
      border: none;
      margin: 0;
      background-color: $dropdown-bg;
      border-top-right-radius: 0;
      padding-left: 10px;
      padding-right: 10px;
      box-shadow: none;
        a {
          color: $app-base-color;
          margin: 0;
          &:hover,
          &:focus {
            background-color: $page-content-background-color;
            color: $app-base-color;
            outline: none;
          }
        }
        &:focus {
          outline: none;
        }
    }
    &.dropup {
      .actions {
        border-top-right-radius: $common-border-radius;
        border-bottom-right-radius: 0;
      }
      .btn {
        border-bottom-left-radius: $common-border-radius;
        border-bottom-right-radius: $common-border-radius;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
}

select.time-select {
  width: 70px !important  ;
}

.permission-detail {
  .title {
    color: $sectionHeaderColor;
  }
}

.assign-container {
  .assign-control {
    position: absolute;
    top: 50%;
    -ms-transform: translateX(-50%);
    transform: translateY(-50%);
    right: -3px;
    font-size: 26px;
    height: auto;
    margin: 0;
    .add {
      color: $positiveBtnBgr;
      &:hover {
        opacity: 0.5;
        color: $positiveBtnBgr;
      }
    }
    .remove {
      color: $negativeBtnBgr;
      &:hover {
        opacity: 0.5;
        color: $negativeBtnBgr;
      }
    }
  }
}

.ball-pulse {
  display: inline-block;
  text-align: center;

  div {
    background-color: $text-active-color;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
    &:nth-child(1) {
      -webkit-animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
      animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }
    &:nth-child(2) {
      -webkit-animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
      animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }
    &:nth-child(3) {
      -webkit-animation: scale 0.75s 0s infinite
        cubic-bezier(0.2, 0.68, 0.18, 1.08);
      animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    }
  }
}

textarea.form-control {
  max-width: 100%;
}

select[disabled] > option {
  color: $text;
}

.modal {
  .fa.fa-remove {
    color: $showError;
    &:hover {
      opacity: 0.5;
      color: $showError;
    }
  }
}

.btn-toolbar {
  margin-left: 0;
  > {
    .btn {
      margin-left: 0;
    }
  }
}
.form-group {
  &:not(.cc-checkbox-wrapper) {
    margin-bottom: 20px;
    label {
      &.control-label,
      &.form-label {
        margin-bottom: 10px;
        display: inline-block;
      }
      &.edit-fare {
        padding-top: 5px;
      }
      &.label-bold {
        font-weight: 700;
      }
      &.total-fare {
        color: $borderColor;
      }
      &.difference-fare {
        color: $btnHeadHover;
      }
    }
  }
  &.form-edit-fare {
    margin-bottom: 10px;
  }
}

.modal-width-tabs {
  .modal-header {
    background-color: $page-content-background-color;
  }
  .modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    .nav-tabs {
      background-color: $menu-background;
      margin-bottom: $commonMargin;
      a {
        padding: 20px;
      }
    }
    .tab-content {
      padding: 0 20px;
      .nav-tabs {
        background-color: transparent;
      }
      .tab-content {
        padding: 0;
      }
    }
  }
}

.form-group-info {
  padding: 10px;
  background: $table-row-hightlighted-bg;
  margin-bottom: 10px;
  border-radius: 6px;
  &.no-background {
    background: transparent;
  }
  .form-group-title {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 15px;
  }
  > *:last-child {
    margin-bottom: 0;
  }
  > .form-group:last-child {
    margin-bottom: 0;
  }
}

.group-general {
  padding: 10px;
  background-color: #2d3039;
  margin-top: 20px;
  border-radius: 5px;
  .text-center.mv-md.btn-toolbar {
    margin-bottom: 0px !important;
  }
  .no-marginBottom {
    margin-bottom: 0px !important;
  }
  .additional-service-table-container {
    margin-top: 20px;
  }
}
.formGroupTitle-otherSetting.form-group-title {
  margin-top: 20px;
}

.bookingTitle {
  color: #32be75;
  width: 100%;
  padding: 10px 0px;
  font-weight: bold;
  border-bottom: 1px solid #20232a;
  font-size: 16px;

  .bookingTitle__text {
    border-bottom: 5px solid #32be74;
    padding-bottom: 8px;
  }
}

.tripTitle {
  color: #32be75;
  width: 100%;
  padding: 10px 0px;
  border-bottom: 1px solid #20232a;
  font-size: 16px;
  margin-left: 10px;

  .tripTitle__text {
    border-bottom: 5px solid #32be74;
    padding-bottom: 8px;
  }
}

.ScrollbarLayout_faceVertical:after,
.ScrollbarLayout_main:hover .ScrollbarLayout_faceVertical:after,
.ScrollbarLayout_faceVertical.public_Scrollbar_faceActive:after {
  width: auto !important;
}

.ScrollbarLayout_faceHorizontal.public_Scrollbar_faceActive:after,
.ScrollbarLayout_main:hover .ScrollbarLayout_faceHorizontal:after,
.ScrollbarLayout_faceHorizontal:after {
  height: auto !important;
}

.modal-sm {
  width: 440px;
}

.filterCorporate {
  background-color: #1c1e24;
  margin: -10px 0 10px 0;
}

.tabsCustom.nav-tabs {
  margin-bottom: 15px;
  &:not(.noPadding) {
    .nav-item {
      padding-left: 0px;
    }
  }
}

.hrefWith_G_Character {
  display: inline-block;
  border-bottom: 1px solid;
  text-decoration: none !important;
  line-height: normal;
}