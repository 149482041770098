@import '../../assets/styles/variables.scss';

#bookId{ 
  .labelCBCue {
    cursor: pointer;
    position: relative;
    padding: 10px 15px 10px 10px;
    height: 100%;
    &.short {
      padding-bottom: 0px;
    }
  }
  &.status {
    padding: 0px !important;
  }
  &:not(.lineShort) {
    .bkId {
      padding-top: 10px;
    }
  }
  .bkId {
    p {
      margin-bottom: 0px;
    }
  }
}

#bookId, #bookIdMobile {
  .labelCBCue {
    .checkboxCue {
      i.fa {
        color: #04be76;
        margin-right: 0px;
        font-size: 15px;
      }
    }
  }
}

.suggestion-content.driver-assign.bookIn {
  .status {
    background-color: $iconColor; /*online*/
  }
}
.suggestion-content.driver-assign.offered {
  .status {
    background-color: $offeredStatusColor; /*dispatching*/
  }
}
.suggestion-content.driver-assign.booked {
  .status {
    background-color: $bookedStatusColor; /*confirmed*/
  }
}
.suggestion-content.driver-assign.arrived {
  .status {
    background-color: $arrivedStatusColor; /*arrived*/
  }
}
.suggestion-content.driver-assignngaged {
  .status {
    background-color: $engagedStatusColor; /*POB*/
  }
}
.suggestion-content.driver-assign.droppedOff {
  .status {
    background-color: $droppedOffStatusColor; /*dropOff*/
  }
}
.suggestion-content.driver-assign.offline {
  .status {
    background-color: $offline; /*offline*/
  }
}

.btn.btnClear {
  color: white;
  margin-right: 10px;
  background-color: #07CE81;
  padding: 10px 20px;
  &:hover {
    color: white;
    background-color: #07CE81;
    padding: 10px 20px;
  }
}
.group_btn_actionsDiv {
  border-radius: 5px;
  display: flex;
  #group_btn_actions, .cc-drop-down.show .btn.dropdown-toggle, .cc-drop-down.show .btn.dropdown-toggle:hover, .cc-drop-down .btn.dropdown-toggle:hover {
    border-radius: 5px;
    background-color: #07CE81;
    color: white;
  }
  &.cc-drop-down .dropdown-menu {
    border-radius: 10px;
    min-width: 100px;
    background-color: #000000;
  }
  &.dropupopdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
    bottom: 114%;
  }
  .btn.dropdown-toggle .caret {
    color: white;
  }
}
.table {
  border-radius: 5px;
  margin: 0;
}
.totalBooking {
  color: white;
  text-align: right;
}
.cue_active {
  position: relative;
  @media only screen and (max-width: 767px) {
    position: inherit;
    .cue-filter-container {
      .header-button-group {
        padding-bottom: 0px;
        .filter-tags {
          padding-bottom: 10px;
        }
      }
    }
  }
}
.drv_btn_cue {
  &.canClick {
    cursor: pointer;
  }
  &.hasLineShort {
    display: flex;
    span {
      margin-left: 5px;
    }
  }
}
.cueBtnContent.custom__lineShort, .cueBtnContent  {
  display: flex;
  button {
    padding: 0px !important;
    p {
      margin: 0px !important;
    }
  }
}
.Select-menu-outer {
  z-index: 99999;
}
.search_bookingGroup {
  .Select-control {
    height: 35px !important;
  }
}

.dropDown_apply {
  margin-top: 10px;
} 

.rearrange-modal.modal-dialog {
  width: 675px;
  transform: inherit !important;
}

.confirm_rearrange {
  position: absolute;
}

.cb_cue {
  margin-bottom: 0px;
  padding: 0px
}
.drag-column {
  width: 400px;
  display: inline-block;
  .cl-drag {
    .fa-plane {
      color: white !important;
      margin-left: 5px !important;
    }
    display: block;
    padding-left: 60px;
    margin: 2px 20px 2px 0;
    font-size: 16px;
    i {
      margin-right: 20px;
    }
  }
}
div#group_btn {
  position: absolute;
  bottom: 22px;
  background-color: #31be76;
  right: 0px;
  left: 0px;
  z-index: 100;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  @media only screen and (max-width: 767px) {
    padding: 0 10px;
    > div {
      display: flex;
    }
  }
  > span {
    color: white;
  }
  .btn-group {
    background: #38d094 !important;
    color: white;
    cursor: pointer;
  }
  @media only screen and (max-width: 767px) {
    position: fixed;
    bottom: 0px;
  }
}
.group-body {
  display: flex;
  .control-label {
    font-size: 16px;
    margin-right: 30px;
  }
  .radio-button-group.form-group {
    margin-left: 30px;
    label.qup-radio-wrapper {
      margin-right: 30px;
      font-size: 16px;
    }
  }
}
.cell_spareTime {
  .cell_content {
    padding: 0px !important;
  }
  .spareTime {
    height: 100%;
    padding: 10px;
    &.spareTime_red {
      background-color: #f0070e;
    }
    &.spareTime_blue {
      background-color: #33c26f;
    }
  }
}
.not_mrb {
  margin-bottom: 0px;
}
.fa-plane {
  color: #04BE76 !important;
  margin-right: 5px !important;
  margin-left: 0px !important;
}
.table thead {
  background: #333333;
  color: $app-base-color;
}
.identInfo, .identInfo:hover {
  cursor: pointer;
  color: white;
  text-decoration: underline;
}
.cue-table-control {
  background: #e6e6e6;
  padding: 5px 10px;
  min-height: 60px;
  .pagination {
    margin: 0;
  }
}
.cue-table-control.header {
  border-radius: 10px 10px 0 0;
}
.cue-table-control.footer {
  border-radius: 0 0 10px 10px;
}

.control-group {
  margin: 0 5px;
  margin-top: 8px;
}
.left-controls .control-group {
  float: left;
}
.right-controls .control-group {
  float: right;
}
.paging-lage {
  padding: 8px 5px;
}
.table-header-controls-container {
  background: #333333;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}
.table-header-control {
  float: left;
  width: 150px;
  margin-right: 5px;
  min-height: 60px;
  label {
    color: $app-base-color;
  }
}

.cue-status-list {
  color: #000;
  span.caret {
    float: right;
    position: relative;
    top: 8px;
  }
  .dropdown {
    display: block;
  }
  .dropdown-menu {
    width: 250px;
    li {
      margin: 5px 5px;
      label {
        font-weight: normal;
        color: #000;
      }
      &:hover {
        background: #f9f9f9;
      }
    }
    input[type='checkbox'] {
      position: absolute;
      left: -9999px;
      + label {
        position: relative;
        padding-left: 1.95em;
        cursor: pointer;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 1.25em;
          height: 1.25em;
          border: 2px solid #ccc;
          background: $app-base-color;
          box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        }
        /* checked mark aspect */
        &:after {
          content: '✔';
          position: absolute;
          top: -0.3em;
          left: 0;
          font-size: 2.5em;
          line-height: 0.9;
          color: #000;
        }
        &.pending {
          &:before {
            background-color: #540f0f;
            border-color: #540f0f;
            color: $app-base-color;
          }
        }
      }
      /* checked mark aspect changes */
      &:not(:checked) {
        + label {
          &:after {
            opacity: 0;
            transform: scale(0);
          }
        }
      }
      &:checked {
        + label {
          &:after {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
      /* disabled checkbox */
      &:disabled {
        + label {
          color: #aaa;
          &:before {
            box-shadow: none;
            border-color: #bbb;
            background-color: #ddd;
          }
        }
        &:checked {
          + label {
            &:after {
              color: #999;
            }
          }
        }
      }
    }
    /* accessibility */
    &:focus {
      + label {
        &:before {
          border: 2px dotted blue;
        }
      }
    }
  }
}

.table-header-control.wide-control {
  width: 170px;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 $app-base-color;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
.modal-header .close {
  font-size: 35px;
  opacity: inherit;
  position: absolute;
  right: 25px;
  top: 10px;
  font-size: 45px;
}
button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.cue-edit {
  cursor: pointer;
}
.cue-edit-input {
  width: 80px;
}
.vip-image {
  background: url('../../constants/staticFiles/vip-1.png') no-repeat;
  background-size: 20px 20px;
  height: 20px;
  width: 20px;
  float: left;
}
.sos-image {
  background: url('../../constants/staticFiles/SOS.png') no-repeat;
  background-size: 35px 20px;
  height: 20px;
  width: 35px;
  float: left;
}
.adjust {
  background: url('../../assets/images/icons/adjust.svg') 0px 3px no-repeat;
  padding: 0px 10px;
}
.adjust-slow {
  background: url('../../assets/images/icons/adjust_low.svg') 0px 3px no-repeat;
  padding: 0px 10px;
}
.adjust-detail {
  background: url("../../assets/images/icons/adjust.svg") 0px 5px no-repeat;
  padding: 0px 10px; }

.adjust-slow-detail {
  background: url("../../assets/images/icons/adjust_low.svg") 0px 5px no-repeat;
  padding: 0px 10px; }
.affiliate-type {
  background-size: auto;
  height: 20px;
  width: 30px;
  background-repeat: no-repeat;
}
.affiliate-type.in {
  background-image: url('../../assets/images/icons/affiliate_in.svg');
}
.affiliate-type.out {
  background-image: url('../../assets/images/icons/affiliate_out.svg');
}
.booking-timeout {
  background: url('../../assets/images/icons/time-out.svg') no-repeat;
  background-size: auto;
  height: 21px;
  width: 21px;
  &.lineShort{
    height: 12px;
    width: 12px;
  }
}
#Auto-refresh-cue {
  width: 250px;
}
.tab-content div.table-container-scrollable {
  overflow-x: scroll;
}

.cue-table {
  .stickyTableCellContainer {
    display: block;
  }
  .status-column {
    text-align: left;
  }
}
.cue-container {
  position: relative;
  .totalBooking {
    color: $text-active-color;
    position: absolute;
    right: 10px;
    font-size: 16px;
  }
  .cue-tabs {
    .nav-item {
      span {
        font-size: 16px;
      }
    }
    label.pending:before {
      background-color: $pendingStatusColor;
      border-color: $pendingStatusColor;
      color: $app-base-color;
    }

    label.dispatching:before {
      background-color: $queueStatusColor;
      border-color: $queueStatusColor;
      color: $app-base-color;
    }

    label.confirmed:before {
      background-color: $confirmedStatusColor;
      border-color: $confirmedStatusColor;
    }

    label.supplierAccepted:before {
      background-color: $supplierAcceptedStatusColor;
      border-color: $supplierAcceptedStatusColor;
    }

    label.booked:before {
      background-color: $bookedStatusColor;
      border-color: $bookedStatusColor;
    }

    label.arrived:before {
      background-color: $arrivedStatusColor;
      border-color: $arrivedStatusColor;
    }

    label.droppedOff:before {
      background-color: $droppedOffStatusColor;
      border-color: $droppedOffStatusColor;
    }

    label.canceledByPassenger:before {
      background-color: $canceledByPassengerStatusColor;
      border-color: $canceledByPassengerStatusColor;
    }

    label.canceledByDriver:before {
      background-color: $canceledByDriverStatusColor;
      border-color: $canceledByDriverStatusColor;
    }

    label.canceledByPartner:before {
      background-color: $canceledByPartnerStatusColor;
      border-color: $canceledByPartnerStatusColor;
    }

    label.canceledByCC:before {
      background-color: $canceledByCCStatusColor;
      border-color: $canceledByCCStatusColor;
    }

    label.noShow:before {
      background-color: $noShowStatusColor;
      border-color: $noShowStatusColor;
      color: $app-base-color;
    }

    label.action:before {
      background-color: $actionStatusColor;
      border-color: $actionStatusColor;
    }

    label.engaged:before {
      background-color: $engagedStatusColor;
      border-color: $engagedStatusColor;
    }

    label.canceledBymDispatcher:before {
      background-color: $canceledBymDispatcherStatusColor;
      border-color: $canceledBymDispatcherStatusColor;
      color: $app-base-color;
    }

    label.canceledByCorpAd:before {
      background-color: $canceledByCorpAdStatusColor;
      border-color: $canceledByCorpAdStatusColor;
      color: $app-base-color;
    }

    label.canceledByAPI:before {
      background-color: $canceledByAPIStatusColor;
      border-color: $canceledByAPIStatusColor;
    }

    label.canceledByWebBooking:before {
      background-color: $canceledByWebBookingStatusColor;
      border-color: $canceledByWebBookingStatusColor;
    }

    label.canceledByTimeout:before {
      background-color: $canceledByTimeoutStatusColor;
      border-color: $canceledByTimeoutStatusColor;
      color: $app-base-color;
    }

    label.completed:before {
      background-color: $completedStatusColor;
      border-color: $completedStatusColor;
      color: $app-base-color;
    }

    label.incident:before {
      background-color: $incidentStatusColor;
      border-color: $incidentStatusColor;
      color: $app-base-color;
    }
  }
}

.cue-table {
  &.hasShift {
    *::selection {
      background: none;
    }
  }
  .status-column {
    .status {
      height: 100%;
      border-left-width: $statusColorWidth;
      border-left-style: solid;
      padding: 10px 15px 10px 10px;
      border-color: $pendingStatusColor;
      &.pending {
        border-color: $pendingStatusColor;
      }
      &.pre {
        border-color: $preStatusColor;
      }
      &.allocated {
        border-color: $preStatusColor;
      }
      &.queue {
        border-color: $queueStatusColor;
      }
      &.offered {
        border-color: $offeredStatusColor;
      }
      &.confirmed {
        border-color: $confirmedStatusColor;
      }
      &.supplierAccepted {
        border-color: $supplierAcceptedStatusColor;
      }
      &.booked {
        border-color: $bookedStatusColor;
      }
      &.arrived {
        border-color: $arrivedStatusColor;
      }
      &.droppedOff {
        border-color: $droppedOffStatusColor;
      }
      &.canceledByPassenger {
        border-color: $canceledByPassengerStatusColor;
      }
      &.canceled {
        border-color: $canceledByPassengerStatusColor;
      }
      &.canceledByDriver {
        border-color: $canceledByDriverStatusColor;
      }
      &.canceledByPartner {
        border-color: $canceledByPartnerStatusColor;
      }
      &.canceledByCC {
        border-color: $canceledByCCStatusColor;
      }
      &.failed {
        border-color: $droppedOffStatusColor;
      }
      &.partialCompleted {
        border-color: $completedStatusColor;
      }
      &.noShow {
        border-color: $noShowStatusColor;
      }
      &.action {
        border-color: $actionStatusColor;
      }
      &.engaged {
        border-color: $engagedStatusColor;
      }
      &.canceledBymDispatcher {
        border-color: $canceledBymDispatcherStatusColor;
      }
      &.canceledByCorpAd {
        border-color: $canceledByCorpAdStatusColor;
      }
      &.canceledByAPI {
        border-color: $canceledByAPIStatusColor;
      }
      &.canceledByWebBooking {
        border-color: $canceledByWebBookingStatusColor;
      }
      &.canceledByTimeout {
        border-color: $canceledByTimeoutStatusColor;
      }
      &.completed {
        border-color: $completedStatusColor;
      }
      &.completedWithoutService {
        border-color: $completedStatusColor;
      }
      &.incident {
        border-color: $incidentStatusColor;
      }
      &.delivering {
        border-color: $engagedStatusColor;
      }
      &.collecting {
        border-color: $bookedStatusColor;
      }
      &.otwMerchant {
        border-color: $bookedStatusColor;
      }
      &.arrivedAndWaitingToCollectItem {
        border-color: $arrivedStatusColor;
      }
    }
  }

  .stickyTableCellContainer {
    .cellTable_row {
      .cellTable_cell {
        .cell_content {
          padding: 10px 10px 10px 15px;
          height: 100%;
          p.address{
            margin: 0 0 5px;
          }
        }
        .btn-header {
          padding-top: 0px;
          &.btnAssign {
            padding: 0px;
          }
        }
        .text-ellipsis {
          margin-bottom: 5px;
        }
        .lineShort {
          // padding: 0px 10px 0px 10px;
          line-height: 1em;
          display: flex;
          align-items: center;
          p.text-ellipsis {
            padding-bottom: 1px;
          }
          p.limit-character-15 {
            max-width: 15ch;
          }
          .custom__lineShort {
            display: flex;
            margin-right: 10px;
            p {
              margin: 0 5px 5px 0;
            }
            div.straight-line{
              border-left: 2px solid #e6e6e6;
              height: 1em;
              margin-right: 5px;
              display: inline-block;
            }
          }
          .cue-edit-container__lineShort {
            margin-bottom: 0;
            p {
              margin: 0;
            }
          }
          .location,.time-fleet,.time-local {
            p {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    &.table-header {
      .cellTable_row {
        .cellTable_cell {
          .cell_content {
            height: auto;
            .line-height {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
  .status-colum-header {
    .public_fixedDataTableCell_cellContent {
      padding-left: 20px;
    }
  }
}

.cue-filter-container {
  .all-checkbox {
    i {
      color: $headerTitleTable;
    }
    label:before {
      background: $modalBodyBgr;
    }
  }
}

.modalAssign.modal-dialog {
  .modal-content {
    height: 100%;
    background-color: #2D3039;
    .modal-header {
      padding: 40px 20px 0px;
      background-color: #2D3039;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      .modal-title {
        font-size: 21px;
        font-weight: 700;
        color: white;
        text-align: left;
      }
      .close {
        color: white;
        top: 20px;
      }
    }
    .modal-body, .modal-footer {
      background-color: #2D3039;
    }
    .modal-footer {
    }
    .searchTitle {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .btn_group {
    display: 'flex'; 
    flex-direction: 'row'; 
    justify-content: 'flex-end'
  }
  @media only screen and (max-width: 767px) {
    position: fixed;
    bottom: 0px;
    height: 50%;
    min-height: 430px;
    margin: 0px;
    .btn_group {
      display: 'flex'; 
      flex-direction: 'row'; 
      justify-content: center
    }
    .modal-footer button.btn {
      margin-left: 10px;
      padding: 6px 12px;
    }
  }
}

@media only screen and (max-width: 420px) {
  .group-right {
    .collapse-filter-title {
      display: none;
    }
  }
}

@media only screen and (max-height: 849px) {
  .cue-container {
    overflow-y: auto;
  }
}
