@import "../../assets/styles/variables.scss";

@import '../../assets/styles/variables.scss';
.flag-icon {
  margin-right: 5px;
}
.fill {
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  width: 100%;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: $text;
}

.App-intro {
  font-size: large;
}

.sidebar {
  background: #ccc;
}
.groupOrderModal .modal-dialog .modal-content {
  overflow: initial
}
#page-content {
  margin-left: $sidebarWidth;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow-x: visible;
  overflow-y: visible;
  background-color: $page-content-background-color;
  height: 100%;
  position:relative;
  > div {
    -webkit-transition: all 0.5s; /* Safari */
    transition: all 0.5s;
  }
  &.collapsed {
    margin-left: $sidebarCollapsedWidth;
    // .navigator {
    //   left: $sidebarCollapsedWidth;
    //   z-index: 100
    // }
  }
  &.have-submenu {
    // .navigator {
    //   left: $sidebarWidth * 2;
    // }
    // .sidebar-wrapper {
    //   left: $sidebarWidth;
    // }
    // &.collapsed {
    //   .navigator {
    //     left: $sidebarWidth + $sidebarCollapsedWidth;
    //   }
    //   .sidebar-wrapper {
    //     left: $sidebarCollapsedWidth;
    //   }
    // }
  }
  .main-content {
    margin-top: $navbar-height;
    height: 100%;
    &.content-with-submenu {
      margin-left: $sidebarWidth;
    }
  }
  .fill-height {
    height: calc(100% - #{$navbar-height});
    min-height: calc(100% - #{$navbar-height});
  }
}

.p.contentMarker {
  display: flex;
  flex-wrap: wrap;
}

.content {
  padding: 0 10px 10px;
  background: $page-content-background-color;
  height: 100%;
}
.content.have-submenu {
  margin: 0;
}
.nav-tabs > li > a {
  background: #ccc;
  font-weight: bold;
}
label {
  display: inline;
}

.notification-container {
  top: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.suggestion-content-driver {
  border-top: 1px solid #989898;
  border-left: 15px solid #989898;
  border-radius: 3px;
  padding: 3px;
  position: relative;
}
.suggestion-content-driver.bookIn {
  border-color: #67be68;
}
.suggestion-content-driver.offered {
  border-color: #512d8c;
}
.suggestion-content-driver.booked {
  border-color: #ffc70c;
}
.suggestion-content-driver.arrived {
  border-color: #f99c1c;
}
.suggestion-content-driver.engaged {
  border-color: #a81e22;
}
.suggestion-content-driver.droppedOff {
  border-color: #ef3937;
}
.suggestion-content-driver.Waiting {
  border-color: #c34d9c;
}
.suggestion-content-driver.bookOff {
  border-color: #989898;
}
td.inactivated,
td.inactivated a {
  color: red;
}
td.activated,
td.activated a {
  color: green;
}
.no-permission-form {
  width: 100%;
  padding: 70px 10px 0 10px;
  text-align: center;
  font-size: 38px;
  font-weight: bold;
  color: #a81e22;
}

/* loading */
.cssload-thecube {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  background: #363A44;

  .pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: $headerTitleTable;
    position: absolute;
    z-index: 3;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    -webkit-animation-name: bounce;
    -moz-animation-name: bounce;
    -o-animation-name: bounce;
    -ms-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    -ms-animation-duration: 1s;
    animation-duration: 1s;
  }
  .pin:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #2f2f2f;
    position: absolute;
    border-radius: 50%;
  }
  .pulse {
    background: rgba(0,0,0,0.2);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    -webkit-transform: rotateX(55deg);
    -moz-transform: rotateX(55deg);
    -o-transform: rotateX(55deg);
    -ms-transform: rotateX(55deg);
    transform: rotateX(55deg);
    z-index:1;
  }
  .pulse:after {
    content: "";
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    -webkit-animation: pulsate 1s ease-out;
    -moz-animation: pulsate 1s ease-out;
    -o-animation: pulsate 1s ease-out;
    -ms-animation: pulsate 1s ease-out;
    animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-box-shadow: 0 0 1px 2px #89849b;
    box-shadow: 0 0 1px 2px #89849b;
    -webkit-animation-delay: 1.1s;
    -moz-animation-delay: 1.1s;
    -o-animation-delay: 1.1s;
    -ms-animation-delay: 1.1s;
    animation-delay: 1.1s;
  }
}
@-moz-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-moz-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@-o-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

.currency-error-message {
  padding-left: 40px;
}

.cc-green {
  color: $text-active-color;
}

ul.fleet-react-autosuggest__suggestions-list {
  list-style-type: none;
  padding-left: 0px !important;
  cursor: pointer;
  max-height: 400px;
  overflow: auto;
  li:hover{
    background: none;
    color:$headerTitleTable;
  }
}

.fleet-react-autosuggest__suggestion--highlighted {
  background-color: #dddddd;
}

.fleet-sugguestion-item {
  min-height: 30px;
  display: flex;
  padding-left: 10px;
  justify-content: flex-start;
  align-items: center;
}

table {
  tr td p {
    word-break: normal;
  }
  tr.p-no-margin td p {
    word-break: keep-all;
    margin-bottom: 0;
  }
}
.bold,
label.bold {
  font-weight: bold;
}

.width-80-percent {
  width: 80%;
}
.model-super-large {
  width: 70%;
  min-width: 800px;
  max-width: 1366px;
}
p.total-edited {
  color: $showError;
}

.form-control.date-time-picker:read-only {
  background-color: $app-base-color;
}

.form-control.date-time-picker:disabled {
  background-color: #eee;
}
.auto-refresh-button {
  padding: 5px 10px;
}
input.form-control.pickup-time {
  background: $formBackground;
  margin: 0;
}
.noti-title {
  font-size: 14px;
  font-weight: bold;
}
.text-trim-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.custom-form {
  .control-label {
    display: flex;
    flex-direction: row;
   // align-items: flex-end;
    align-items: center;
  }
  // .form-group {
  //   min-height: 34px;
  //   display: flex;
  //   flex-direction: column;
  //   // align-items: flex-start;
  //   justify-content: center;
  // }
  .form-group.none-min-height,
  .control-label.none-min-height {
    min-height: 0;
  }
  .form-group.flex-1 {
    flex: 1;
  }
  .form-group.flex-2 {
    flex: 2;
  }
  .form-group.direction-row {
    flex-direction: row;
  }
  .form-group.jc-end {
    justify-content: flex-end;
  }
  .modal-header .close {
    right: 60px;
    font-size: 40px;
  }
  .modal-footer {
    min-height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}
.flex-start-default {
  min-height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.table > tbody > tr > td {
  border-top: none;
}

.table > tbody > tr > td:first-child,
.table > thead > tr > th:first-child {
  padding-left: 20px;
}

.table.custom-form > tbody > tr > td:last-child {
  padding-left: 30px;
}

.table > thead > tr > th {
  border-bottom: none;
}

.table th, .table td, .table thead th {
  border-top: none;
}

.table > tbody > tr > td {
  padding-top: 4px;
  padding-bottom: 4px;
}

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: #f2f2f2;
}

.action-row {
  width: 120px;
  text-align: center;
  justify-content: center;
}

i.fa.fa-question-circle {
  font-size: 18px;
}

.custom-form {
  .form-group.button-right-group {
    min-height: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  .form-group .center-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.flex-bw-center {
  align-items: center;
  justify-content: space-between;
}

.modal-dialog {
  margin-top: 100px;
}

.tooltip {
  text-align: left;
  vertical-align: left;
  word-wrap: break-word;
  white-space: pre-line;
  z-index: 10000;
}

.tooltip-inner {
  text-align: left;
  word-wrap: break-word;
  white-space: pre-line;
}

@media (max-width: 768px) {
  .wrapper {
    padding-left: 0;
  }
  #page-content {
    margin-left: $sidebarCollapsedWidth;
  }
  .wrapper.toggled {
    padding-left: 250px;
  }

  #sidebar-wrapper {
    width: 50px;
  }
  .page-content-wrapper {
    padding: 20px;
    position: relative;
  }
  .custom-form.modal-dialog.width-700 {
    width: 700px;
  }
}

div.space-vertical-5 {
  height : 5px;
}

div.space-vertical-10 {
  height : 10px;
}

div.space-vertical-15 {
  height : 15px;
}

div.space-vertical-20 {
  height : 20px;
}

span.danger {
  color : $showError
}
.group_cell {
  display: flex;
}
.column-filter-icon {
  background-image: url("../../assets/images/icons/filter.svg");
  background-repeat: none;
  background-size: 100% 100%;
  width: 29px;
  height: 18px;
  background-position: center center;
}

.copy-button {
  border-top-left-radius: initial;
  border-bottom-left-radius: initial;
  &.btn {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.suggestion-content.driver-assign {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  border: none;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;

  .status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    background-color: $menu-icon-color; /*offline*/
    margin-right: 10px;
    margin-top: 5px;
  }
  .calendar {
    // width: 15px;
    // height: 15px;
    margin-top: 1px;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .info {
    display: flex;
    flex: 1;
    flex-direction: column;

    .driverInfo__name {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.btnStatus {
  font-size: 15px;
  display: inline-block;
  padding: 4px 16px;
  border-radius: 9999px;
  text-transform: capitalize;
}
.bk-pending, .pm-inactive {
  color: #F97066 !important;
  background-color: rgba(249, 112, 102, 0.12);
}
.bk-paid, .pm-success {
  color: #04BE76 !important;
  background-color: rgba(4, 190, 118, 0.12)
}
.pm-fullRefund, .pm-partialRefund {
  color: #FEC84B !important;
  background-color: #FEC84B1F
}
.pm-pending {
  color: white !important;
  background-color: #202123;
}
.bk-partial {
  color: rgb(255, 165, 0) !important;
  background-color: #393c41;
}
