@import "./variables.scss";

.form-section-title {
  color: $sectionHeaderColor;
}
.list-unstyled {
  list-style: none;
}

.autoplaces-input {
  &.has-recent {
    input {
      // padding-right: 75px;
    }
  }
  &.disabled {
  }
  input {
    padding-right: 25px;
  }
  .remove-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 15px;
    cursor: pointer;
    i {
      font-size: 20px;
      color: $autoPlacesRemoveColor;
    }
  }
}

// margin, padding, display
// ph: horizontal padding (right and left)
// pv: vertical padding (top and bottom)
// pr: padding right
// pl: padding left
// pb: padding bottom
// pt: padding top
.mh-sm {
  margin-left: $margin-sm !important;
  margin-right: $margin-sm;
}
.mv-sm {
  margin-top: $margin-sm;
  margin-bottom: $margin-sm;
}
.ml-sm {
  margin-left: $margin-sm;
}
.mr-sm {
  margin-right: $margin-sm;
}
.mt-sm {
  margin-top: $margin-sm;
}
.mb-sm {
  margin-bottom: $margin-sm;
}

.mh {
  margin-left: $margin;
  margin-right: $margin;
}
.mv {
  margin-top: $margin;
  margin-bottom: $margin;
}
.ml {
  margin-left: $margin;
}
.mr {
  margin-right: $margin;
}
.mt {
  margin-top: $margin;
}
.mb {
  margin-bottom: $margin;
}

.mh-lg {
  margin-left: $margin-lg !important;
  margin-right: $margin-lg !important;
}
.mv-lg {
  margin-top: $margin-lg !important;
  margin-bottom: $margin-lg !important;
}
.ml-lg {
  margin-left: $margin-lg !important;
}
.mr-lg {
  margin-right: $margin-lg !important;
}
.mt-lg {
  margin-top: $margin-lg !important;
}
.mb-lg {
  margin-bottom: $margin-lg !important;
}

.mh-md {
  margin-left: $margin-md !important;
  margin-right: $margin-md !important;
}
.mv-md {
  margin-top: $margin-md !important;
  margin-bottom: $margin-md !important;
}
.ml-md {
  margin-left: $margin-md !important;
}
.mr-md {
  margin-right: $margin-md !important;
}
.mt-md {
  margin-top: $margin-md !important;
}
.mb-md {
  margin-bottom: $margin-md !important;
}

.mh-xl {
  margin-left: $margin-xl !important;
  margin-right: $margin-xl !important;
}
.mv-xl {
  margin-top: $margin-xl !important;
  margin-bottom: $margin-xl !important;
}
.ml-xl {
  margin-left: $margin-xl !important;
}
.mr-xl {
  margin-right: $margin-xl !important;
}
.mt-xl {
  margin-top: $margin-xl !important;
}
.mb-xl {
  margin-bottom: $margin-xl !important;
}

.m0 {
  margin: 0 !important;
}
.mh0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mv0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.ml0 {
  margin-left: 0 !important;
}
.mr0 {
  margin-right: 0 !important;
}
.mt0 {
  margin-top: 0 !important;
}
.mb0 {
  margin-bottom: 0 !important;
}

.flag-container {
  //set flag in phone input margin 0 because it has the same class "mr" and "ml"
  .mh {
    margin: 0px !important;
  }
  .ml {
    margin: 0px !important;
  }
}

// padding
.ph-sm {
  padding-left: $padding-sm !important;
  padding-right: $padding-sm !important;
}
.pv-sm {
  padding-top: $padding-sm !important;
  padding-bottom: $padding-sm !important;
}
.pl-sm {
  padding-left: $padding-sm !important;
}
.pr-sm {
  padding-right: $padding-sm !important;
}
.pt-sm {
  padding-top: $padding-sm !important;
}
.pb-sm {
  padding-bottom: $padding-sm !important;
}

.ph {
  padding-left: $padding !important;
  padding-right: $padding !important;
}
.pv {
  padding-top: $padding !important;
  padding-bottom: $padding !important;
}
.pl {
  padding-left: $padding !important;
}
.pr {
  padding-right: $padding !important;
}
.pt {
  padding-top: $padding !important;
}
.pb {
  padding-bottom: $padding !important;
}

.ph-lg {
  padding-left: $padding-lg;
  padding-right: $padding-lg;
}
.pv-lg {
  padding-top: $padding-lg;
  padding-bottom: $padding-lg;
}
.pl-lg {
  padding-left: $padding-lg;
}
.pr-lg {
  padding-right: $padding-lg;
}
.pt-lg {
  padding-top: $padding-lg;
}
.pb-lg {
  padding-bottom: $padding-lg;
}

.ph-md {
  padding-left: $padding-md;
  padding-right: $padding-md;
}
.pv-md {
  padding-top: $padding-md;
  padding-bottom: $padding-md;
}
.pl-md {
  padding-left: $padding-md;
}
.pr-md {
  padding-right: $padding-md;
}
.pt-md {
  padding-top: $padding-md;
}
.pb-md {
  padding-bottom: $padding-md;
}

.ph-xl {
  padding-left: $padding-xl;
  padding-right: $padding-xl;
}
.pv-xl {
  padding-top: $padding-xl;
  padding-bottom: $padding-xl;
}
.pl-xl {
  padding-left: $padding-xl;
}
.pr-xl {
  padding-right: $padding-xl;
}
.pt-xl {
  padding-top: $padding-xl;
}
.pb-xl {
  padding-bottom: $padding-xl;
}

.p0 {
  padding: 0 !important;
}
.ph0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.pv0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.pl0 {
  padding-left: 0 !important;
}
.pr0 {
  padding-right: 0 !important;
}
.pt0 {
  padding-top: 0 !important;
}
.pb0 {
  padding-bottom: 0 !important;
}

.display-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-start {
  justify-content: flex-start;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.cursor-pointer {
  cursor: pointer;
}

// END PADDING MARGIN

// Text utils
.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-underline {
  text-decoration: underline;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-base {
  color: $app-base-color;
}
.text-active {
  color: $text-active-color;
}
a.text-base {
  &:focus,
  &:hover {
    color: $text-active-color;
  }
}
a.text-active {
  &:focus,
  &:hover {
    opacity: 0.5;
    color: $text-active-color;
  }
}
.text-remove {
  color: $showError;
}
a.text-remove {
  &:focus,
  &:hover {
    color: $showError;
    opacity: 0.5;
  }
}
// End Text utils

// width
.w-100 {
  width: 100%;
}
.overflow-auto {
  overflow: auto;
}
.vertical-flow-auto {
  overflow-y: auto;
}
.pseudo-none {
  &:after,
  &:before {
    display: none;
  }
}

.transform-none {
  text-transform: none;
}

.text-more-info {
  color: #63666f;
}