@import '../../../assets/styles/variables.scss';

.qup-sticky-table {
  .public_fixedDataTable_main {
    border: none;
  }
  .public_fixedDataTableRow_highlighted,
  .public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
    background-color: $table-row-hightlighted-bg;
  }
  .public_fixedDataTableCell_main {
    background-color: $navbar-background;
    color: $app-base-color;
    border: none;
    // .fixedDataTableCellLayout_columnResizerContainer {
    //   &:hover {
    //     .public_fixedDataTableCell_columnResizerKnob {
    //       background-color: transparent;
    //     }
    //   }
    // }
    .public_fixedDataTableCell_cellContent {
      padding: 8px 20px 8px 15px;
    }
    .header-align-toolbar {
      .public_fixedDataTableCell_cellContent {
        padding-left: 20px;
      }
    }
    .stickyTableCellContainer {
      &.cell-align-toolbar,
      &.header-align-toolbar {
        .cell_content {
          padding-left: 15px;
        }
      }
    }
    .public_fixedDataTableCell_wrap1 {
      height: 100%;
    }
    .text-ellipsis {
      .public_fixedDataTableCell_cellContent {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .fixedDataTableRowLayout_rowWrapper {
    .fixedDataTableRowLayout_main {
      overflow: visible;
      // light
      background-color: $navbar-background;
      .public_fixedDataTableCell_main {
        overflow: visible;
      }
      .fixedDataTableCellGroupLayout_cellGroup {
        overflow: visible;
      }
      &.public_fixedDataTableRow_highlighted {
        // dark
        background-color: $table-row-hightlighted-bg;
      }
      .public_fixedDataTableCell_alignRight {
        display: flex;
        justify-content: flex-end;
        .table-header {
          span {
            margin-right: 0;
          }
          .cell_content {
            > div {
              justify-content: flex-end;
            }
          }
        }
      }
      &:not(.fixedDataTableLayout_header) {
        .public_fixedDataTable_scrollbarSpacer {
          width: 0 !important;
        }
      }
    }
    // header
    .fixedDataTableLayout_header,
    .fixedDataTableLayout_footer {
      // background-image: linear-gradient(#fff, #efefef);
      background-image: none;
      background-color: $menu-background;
      border: none;
      .public_fixedDataTableCell_main {
        background-color: $menu-background;
        background-image: unset;
      }
      .public_fixedDataTable_scrollbarSpacer {
        background-color: $menu-background;
        background-image: linear-gradient($menu-background, $menu-background);
        top: 0;
      }
    }
    .public_fixedDataTableRow_fixedColumnsDivider {
      border: none;
    }
    &:hover {
      .fixedDataTableRowLayout_main {
        background-color: $table-row-hover-bg;
        .public_fixedDataTableCell_main {
          background-color: $table-row-hover-bg;
        }
        &.public_fixedDataTable_header,
        &.public_fixedDataTable_footer {
          background-color: $menu-background;
          .public_fixedDataTableCell_main {
            background-color: $menu-background;
          }
        }
      }
    }
  }

  .ScrollbarLayout_face {
    transition-duration: unset;
    transition-timing-function: unset;
    &:after {
      border-radius: $common-border-radius;
    }
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }

  .ScrollbarLayout_mainVertical {
    width: 12px;
    background-color: $menu-background;
    border: none;
    .ScrollbarLayout_faceVertical {
      &:after {
        left: 1px;
        right: 1px;
        background-color: $menu-icon-color;
      }
    }
  }

  .public_fixedDataTable_horizontalScrollbar {
    height: 12px !important;
    background-color: $menu-background;
    border: none;
    bottom: 5px;
    position: relative;
    .ScrollbarLayout_mainHorizontal {
      height: 12px;
      background-color: $menu-background;
      border: none;
      top: 0px;
      bottom: unset;
      .ScrollbarLayout_faceHorizontal {
        // transform: translate3d(0px, 0px, 0px) !important;
        &:after {
          bottom: 1px;
          top: 1px;
          background-color: $menu-icon-color;
        }
      }
    }
  }

  .public_fixedDataTable_header,
  .public_fixedDataTable_hasBottomBorder {
    border: none;
  }

  .fixedDataTableLayout_rowsContainer {
    > .fixedDataTableRowLayout_rowWrapper {
      z-index: 1 !important;
    }
  }

  .table-header {
    color: $text-active-color;
    height: 100%;
    width: 100%;
    font-weight: bold;
    background-color: $menu-background;
    .cell_content {
      line-height: 17px;
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &.cc-checkbox-wrapper,
        &.table-selectbox {
          display: block;
        }
      }
    }
    i:not(.fa-check) {
      margin: 0;
      margin-left: 10px;
      color: $app-base-color;
      position: relative;
      z-index: 100000;
    }
    span {
      margin-right: 0;
    }
    &.group-column-no-sub {
      height: 200%;
      top: -100%;
      position: absolute;
    }
  }
  &.has-group-column {
    .table-header.group-header-wrapper {
      border-left: 1px solid $page-content-background-color;
      border-bottom: 1px solid $page-content-background-color;
      .cell_content {
        > div {
          display: block;
        }
      }
    }
  }
  .column_divider {
    border-left: 1px solid $menu-background;
    &.table-header {
      border-color: $page-content-background-color;
    }
  }
  .last-row-extend-divider {
    position: absolute;
    top: 0px;
    bottom: 0px;
  }
  .clickable {
    color: $app-base-color;
    text-decoration: none;
    display: inline-block;
    vertical-align: bottom;
    max-width: 100%;
    &:hover {
      text-decoration: underline;
    }
  }

  .stickyTableCellContainer {
    height: 100%;
    width: 100%;
    display: table;
    .cellTable_row {
      display: table-row;
    }
    .cellTable_cell {
      display: table-cell;
      vertical-align: middle;
      .cell_content {
        overflow: visible;
        padding: 8px 20px 8px 15px;
        &.no-padding {
          padding: 0;
        }

        .item_drag_Name {
          .item_drag {
            display: flex;
            align-items: center;
            cursor: move;
            cursor: grab;
            cursor: -webkit-grab;
            cursor: -moz-grab;
            &:active {
              cursor: grabbing;
              cursor: -webkit-grabbing;
              cursor: -moz-grabbing;
            }
            svg {
              margin-right: 5px;
            }
            .item_drag_icon {
              position: relative;
              width: 3px;
              height: 3px;
              margin-left: 2px;
              top: 3px;
              right: 5px;
              background-color: #e6e6e6;
              border-radius: 50%;
              &::after,
              &::before
              {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0px;
                background-color: inherit;
                border-radius: inherit;
              }

              &::after {
                top: 10px;
              }
              &::before {
                top: 5px;
              }
            }
          }
        }
      }


      

      // .priority {
      //   display: flex;
      //   justify-content: center;
        // .item-drag svg{
        //   margin-right: 15px;
      //     height: 2rem;
      //     color: #fff;
      //   }
      // }

    }
    &.text-ellipsis {
      .cellTable_cell {
        .cell_content {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .cell_content {
      .table-actions-dropdown {
        button.dropdown-toggle {
          width: auto;
          color: $text-active-color;
          padding: 10px 10px 2px;
          background-color: transparent;
          border: none !important;
          box-shadow: none !important;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          outline: none;
          min-width: unset;
          i.fa {
            margin: 0;
          }
          .caret {
            display: none;
            border-top: 4px dashed;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
            border-bottom: none;
          }
          &::after {
            display: none;
          }
        }
        &.show {
          .dropdown-toggle {
            background-color: $dropdown-bg;
          }
        }
        .actions {
          left: unset;
          right: 0;
          border: none;
          margin: 0;
          background-color: $dropdown-bg;
          border-top-right-radius: 0;
          box-shadow: none;
          padding-left: 10px;
          padding-right: 10px;
            a {
              color: $app-base-color;
              margin: 0;
              &:hover,
              &:focus {
                background-color: $page-content-background-color;
                color: $app-base-color;
                outline: none;
              }
            }
            &:focus {
              outline: none;
            }
        }
        &.dropup {
          .actions {
            border-top-right-radius: $common-border-radius;
            border-bottom-right-radius: 0;
          }
          .btn {
            border-bottom-left-radius: $common-border-radius;
            border-bottom-right-radius: $common-border-radius;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
          }
        }
      }
    }
  }

  &.content-top {
    .stickyTableCellContainer:not(.table-header) {
      .cellTable_cell {
        vertical-align: top;
      }
    }
  }
  .table-selectbox {
    margin: 0;
    &.cc-checkbox-wrapper {
      margin-top: 5px;
    }
    label {
      vertical-align: super;
      &:before {
        border: 1px solid $page-content-background-color;
      }
    }
  }
  //checkbox
  .all-checkbox {
    i {
      color: $headerTitleTable;
    }
    label:before {
      background: $modalBodyBgr;
    }
  }
  .fixedDataTableCellLayout_columnResizerContainer {
    background-color: #363a44;
  }
  .fixedDataTableCellLayout_columnResizerContainer {
    width: 3px;
  }
  .fixedDataTableCellLayout_columnReorderContainer, .fixedDataTableCellLayout_columnResizerContainer {
    opacity: 0;
  }
  .public_fixedDataTableCell_hasReorderHandle {
    &:hover {
      .fixedDataTableCellLayout_columnReorderContainer, .fixedDataTableCellLayout_columnResizerContainer {
        opacity: 100;
      }
    }
    
  }
}
.public_fixedDataTableColumnResizerLine_main {
  border: 2px solid #252830;
}
.fixedDataTableCellLayout_columnReorderContainer {
  width: 100%;
  position: absolute;
  left: 0;
  background-color: #25283000;
}
