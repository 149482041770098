@import './../../assets/styles/variables.scss';

.countries-list-container ul.dropdown-menu {
  height: 200px;
  overflow-x: scroll;
}

.btn_showMore {
  color: white;
  margin-bottom: 40px;
  span {
    cursor: pointer;
  }
  img {
    width: 25px;
    margin-right: 10px;
  }
}

.newbooking-form .cue-date-picker-group .form-control {
  margin-bottom: 0px;
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.autocomplete {
  position: relative;
  a {
    position: absolute;
    right: 5px;
    top: 10px;
  }
}
.hidden {
  display: none;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open {
  .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 40px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
.complete-with-payment-modal {
  .modal-dialog {
    width: 550px;
    .modal-body {
      padding: 20px;
    }
    .modal-content {
      overflow: visible;
      .additional-services-item {
        display: block;
      }
    }
    .modal-footer {
      padding: 20px;
      text-align: center;
    }
  }
  .modal-footer {
    button {
      margin-left: 20px !important;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.newbooking-form {
  padding-left: 10px;
  padding-right: 10px;
  .estimate-header {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;
    padding-top: 15px;
    .estimate-title {
      padding-bottom: 0px;
      font-size: 16px;
      color: $headerTitleTable;
      flex: auto;
      justify-content: space-between;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .estimate-route {
      color: $menu-icon-color;
      flex: 2;
      display: flex;
      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-wrap: wrap;
      }
    }
    &.invoiceTrip {
      padding-bottom: 0px !important;
    }
  }

  .form-group-title {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .radio-button-group {
    margin-bottom: 10px;
  }
  span.promotion-value {
    color: $text;
  }
  .control-label {
    margin-bottom: 10px;
    min-height: 21px; //set min-height 21 for better UI
    display: inline-block;
  }
  .third-party {
    margin-bottom: 5px;
  }
  .booking-date-picker {
    position: relative;
    z-index: 99;
    .datepicker-input {
      margin-bottom: 0;
    }
    .datePicker-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 8;
      .rdtPicker {
        box-shadow: 0 4px 12px rgba(6, 6, 6, 0.175);
      }
    }
    .pickup-time-now {
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      padding: 0 12px 0 8px;
      cursor: pointer;
      color: $text-active-color;
      z-index: 7;
      background-color: $formBackground;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      height: fit-content;
      span {
        line-height: 34px;
      }
    }
    .rdt {
      height: 34px;
      background-color: $formBackground;
      border-radius: 4px;
      .rdtPicker {
        top: -1px;
      }
      input.form-control:not(:disabled) {
        background-color: transparent;
        position: absolute;
        cursor: pointer;
        height: 34px;
        z-index: 4;
        margin: 0px;
      }
      input.btn-now {
        top: 5px;
        z-index: 6;
      }
      img {
        top: 8px;
      }
    }
  }
  .react-autosuggest__container--open {
    .react-autosuggest__suggestions-container {
      display: block;
      position: absolute;
      width: 100%;
      font-weight: 300;
      font-size: 14px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      z-index: 2;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      color: $text;
      background-color: $dropdown-bg;
      border: 1px solid $borderColor;
      border-color: transparent;
    }
  }
  .assign-driver-container {
    .react-autosuggest__container--open {
      .react-autosuggest__suggestions-container {
        border: none;
      }
    }
  }
  .react-autosuggest__suggestion--highlighted {
    background-color: #3b3b3b;
  }

  .suggestion-content.driver-assign {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    border: none;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;

    .status {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      background-color: $menu-icon-color; /*offline*/
      margin-right: 10px;
      margin-top: 5px;
    }
    .calendar {
      // width: 15px;
      // height: 15px;
      margin-top: 1px;
      img {
        width: 15px;
        height: 15px;
      }
    }
    .info {
      display: flex;
      flex: 1;
      flex-direction: column;

      .driverInfo__name {
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .suggestion-content.driver-assign.bookIn {
    .status {
      background-color: $iconColor; /*online*/
    }
  }
  .suggestion-content.driver-assign.offered {
    .status {
      background-color: $offeredStatusColor; /*dispatching*/
    }
  }
  .suggestion-content.driver-assign.booked {
    .status {
      background-color: $bookedStatusColor; /*confirmed*/
    }
  }
  .suggestion-content.driver-assign.arrived {
    .status {
      background-color: $arrivedStatusColor; /*arrived*/
    }
  }
  .suggestion-content.driver-assignngaged {
    .status {
      background-color: $engagedStatusColor; /*POB*/
    }
  }
  .suggestion-content.driver-assign.droppedOff {
    .status {
      background-color: $droppedOffStatusColor; /*dropOff*/
    }
  }
  .suggestion-content.driver-assign.offline {
    .status {
      background-color: $offline; /*offline*/
    }
  }
  .new-booking-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
  }
  .info-content {
    min-height: 650px;
  }
  .booking-map-container {
    height: 300px;
    @media(max-width: 1300px) {
      height: 400px;
    }
  }

  .booking-map {
    overflow: hidden;
  }

  .trip-estimate-container {
    width: 100%;
    padding: 20px;
    // padding-left: 60px;
    // padding-right: 20px;
    // padding-bottom: 20px;
    min-height: 100px;
    background-color: $formBackground;
    margin-top: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
    .estimateInfoTransport {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      gap: 7px;
      .item {
        flex: 1 1 calc((100% - 14px) / 3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        max-width: calc((100% - 14px) / 3);
        span {
          &:first-child {
            color: $menu-icon-color;
            font-size: 15px !important;
            &.fareTotal {
              color: $text !important;
              font-size: 17px !important;
              padding-top: 5px !important;
              padding-bottom: 5px !important;
            }
          }
          &:last-child {
            color: $text;
            font-size: 17px;
            padding-bottom: 5px;
          }
        }
      }
    }
    .note {
      color: $menu-icon-color;
      font-size: 14px;
      // font-style: italic;
    }
  }

  .add-extra-des-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    .add-icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    span {
      color: $iconColor;
    }
  }

  .form-group {
    .qup-radio-wrapper {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  button {
    margin-right: 10px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.modal-body {
  color: $text;
}

.confirm {
  .update-book-detail-modal.modal-dialog {
    max-width: 600px;
  }
}

.driver-calendar-modal {
  .modal-dialog {
    width: 450px;
    .modal-header {
      text-align: center;
      height: 80px;
      padding: 15px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .cal-control {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .fa {
        color: $text-active-color;
        font-size: 27px;
        &.fa-caret-left {
          margin-right: 15px;
        }
        &.fa-caret-right {
          margin-left: 15px;
        }
      }
      span {
        color: $text-active-color;
        font-size: 17px;
      }
      button {
        //right : 15px;
        //top:0px;
        //margin-top: 0px;
        span {
          color: $text;
          font-size: 35px !important;
        }
      }
    }
    .modal-body {
      padding: 20px;
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }

  table {
    width: 100%;
  }
  .job-not-found {
    vertical-align: middle;
    text-align: center;
    color: $text;
    min-height: 100px;
    &:hover {
      color: $text;
    }
    span {
      line-height: 100px;
    }
  }
  .driver-name {
    color: $text;
  }
  .cal-schedule-item {
    color: $text;
    display: flex;
    flex-direction: row;
    &:not(.first-item) {
      border-top: 1px solid $modalHeadBgr;
    }
    td:first-child {
      width: 385px;
    }
    .fa {
      font-size: 25px;
      color: $iconColor;
      margin-right: 20px;
      margin-left: 7px;
      line-height: 50px;
      &:hover {
        color: $iconColor;
      }
    }
    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      p {
        margin: 0;
      }
      .passenger {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        div {
          flex: 1;
          &.book-id {
            font-size: 15px;
          }
          &.pickup-time {
            text-align: center;
            font-size: 15px;
            color: $iconColor;
          }
          &.passenger-info {
            text-align: right;
            color: $menu-icon-color;
          }
        }
      }

      .location {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        overflow: hidden;
        text-emphasis: none;
        &.destination {
          img {
          }
        }
        img {
          width: 15px;
          margin-right: 10px;
        }
        span {
          line-height: 30px;
          white-space: nowrap;
        }
        .address-container {
          width: 100%;
        }
      }
    }
    .date-time {
      width: 185px;
      text-align: right;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: row;
      color: $iconColor;
      :hover {
        color: $iconColor;
      }
    }

    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 2px 5px;
  :hover {
    background-color: #3b3b3b;
  }
}

.react-autosuggest__suggestion--focused {
  background-color: #0c7eaf;
  color: $app-base-color;
}

.highlight {
  color: #ee0000;
  font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #3b3b3b;
}
.estimate {
  margin: 0 20px;
  .fa {
    margin-right: 5px;
    display: inline-block;
    width: 20px;
    text-align: center;
    font-size: 1.2em;
  }
}
#input-phone-temp {
  display: none;
}
.customer-phone {
  position: relative;
}
.customer-rank-container {
  position: absolute;
  top: 0;
  right: 0;
}
.rank {
  width: 35px;
  height: 35px;
  span {
    line-height: 35px;
    color: $menu-icon-color;
    font-weight: 600;
    font-size: 12px;
    &:hover {
      color: $VIPOptionsColor;
    }
  }
  &.vip {
    span {
      color: $VIPOptionsColor;
    }
  }
}
.hover.rank:hover {
  cursor: pointer;
}

.newbooking-form {
  .place-container {
    input {
      padding-right: 10px;
    }
    &.has-recent-input.has-address {
      .recent-container {
        right: 0px;
      }
      .remove-icon {
        right: 35px;
      }
      input {
        padding-right: 55px;
      }
    }
    &.has-recent-input {
      &:not(.has-address) {
        .recent-container {
          right: 0px;
        }
        input {
          padding-right: 35px;
        }
      }
    }
    &.has-address {
      &:not(.has-recent-input) {
        .remove-icon {
          right: 10px;
        }
        input {
          padding-right: 35px;
        }
      }
      .autoplaces-input.disabled {
        input {
          padding-right: 10px;
        }
      }
    }
  }

  .form-group {
    &.has-error {
      input,
      .form-control {
        border: 1px solid $showError;
      }
    }
  }
}

.recent-container {
  display: inline-block;
  width: 35px;
  height: 33px;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  .recent-btn {
    background-color: transparent;
    border: none;
    color: $recentButtonColor;
    width: 18px;
    height: 20px;
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }
  .dropdown {
    &.btn-group {
      &.show {
        .btn.btn-primary {
          background: transparent;
          border-color: transparent;
          box-shadow: none;
        }
      }
    }
    button {
      width: 100%;
      background: transparent;
      &.btn.btn-primary:hover {
        &.active {
          background: transparent;
          border-color: transparent;
          box-shadow: none;
        }
      }
    }
  }

  .dropdown-menu {
    width: 100%;
  }
}
.input-group.has-recnet {
  margin-right: 70px;
}

.autocomplete__cover {
  position: absolute;
  width: 100%;
  z-index: 999;
  padding: 5px 0;
  .autocomplete__container {
    padding-top: 10px;
    width: 100%;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    background-color: $columnFilterDropdownBg;
    border-radius: 5px;
  }
}

.place-item {
  overflow: hidden;
  &.single {
    padding: 10px;
    &:hover {
      background-color: $page-content-background-color;
    }
  }
  &.multiple {
    > div {
      padding: 0 10px 5px 10px;
    }
  }
  > a {
    width: 100%;
    &:hover {
      color: $text;
      text-decoration: none;
    }
    cursor: pointer;
  }
  p {
    color: $text;
    margin: 0;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .place-item__allias {
    i.fa {
      font-size: 24px;
      color: $iconColor;
    }
    span {
      font-size: 1.1em;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .place-item__addressSearch {
    font-weight: 300;
    font-size: 0.9em;
    margin-left: 30px;
    margin-top: 3px;
    color: lightgray;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .places-autocomplete-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  ul.place-item__list {
    list-style: none;
    padding: 0;
    li {
      cursor: pointer;
      padding: 4px 10px;
      &:hover {
        background-color: $page-content-background-color;
      }
      .place-item__allias {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        &::before {
          content: '\2022';
          color: $iconColor;
          font-weight: bold;
          font-size: 1.5em;
          margin-left: 30px;
          margin-right: 5px;
        }
        span {
          font-size: 0.97em;
          font-weight: 400;
          align-self: center;
        }
      }
      a {
        text-decoration: none;
        margin: 0;
        margin-left: 30px;
        padding: 0;
        color: $text;
      }
    }
  }
}

.autocomplete__placeContainer {
  position: absolute;
  width: 100%;
  z-index: 999;
  padding: 5px 0;
  background-color: $menu-background;
  .place-item {
    .place-item__allias {
      span {
        font-size: 1em;
      }
    }
  }
}

.pointModal .chauffeurBK {
  width: 100% !important;
  left: 0px !important;
}

.place-container {
  display: inline-block;
  width: 100%;
  position: relative;
  right: 0;
  top: 0;
  .recent-places-container {
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow: hidden;
    border-radius: 3px;
    overflow-y: auto;
    z-index: 9999; //greater than recent button
    background-color: $dropdown-bg;
    padding: 5px;
    &.chauffeurBK {
      width: 153%;
      left: -31px;
    }
    > {
      ul {
        margin: 0;
      }
    }
    .place-item {
      padding: 5px;
      a {
        &:hover {
          color: $text;
          text-decoration: none;
        }
        color: $text;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        i.fa {
          width: 24px;
          font-size: 24px;
          margin-left: 7px;
          display: flex;
          color: $iconColor;
        }
        span {
          display: flex;
          flex: 1;
        }
      }
    }
  }
}

.recent-btn.btn {
  float: right;
}

.calendar-container {
  position: absolute;
  top: 10px;
  right: 5px;
  color: blue;
  .calendar:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.driver-calendar {
  position: inherit;
  height: 480px;
  max-height: 100%;
  overflow-y: scroll;
}
.driver-calendar-container {
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  border: 2px dashed darkgoldenrod;
  position: relative;
  max-height: 100%;
}

.driver-calendar-container-header {
  text-align: center;
}
.driver-calendar-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.cal-control button {
  margin: 0 5px;
}
.cal-schdule {
  border: 1px solid #cccccc;
  margin-top: 10px;
}
.cal-item-date {
  width: 150px;
  border-right: 1px solid #cccccc;
  text-align: center;
}
li.recent-item a {
  display: block;
  white-space: normal;
  padding: 3px;
}
.extra-services-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 34px;
  flex-direction: row;
  cursor: pointer;
}

.extra-services-row-modal {
  min-height: 50px;
}

.newbooking-form {
  &.complete-with-payment {
    position: relative;
    top: 0;
    overflow: unset;
  }
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding-bottom: 50px;
  background-color: $modalBodyBgr;
  overflow: auto;
  .autoplaces-input {
    .form-control {
      margin-bottom: 0px;
    }
  }
  .form-control {
    background-color: $formBackground;
    margin: 0;
    margin-bottom: 20px;
    &.third-party {
      margin-bottom: 0px;
    }
  }
  .additional-service-form {
    position: relative;
    .additional-services-item {
      position: absolute;
      top: 34px;
      color: $text;
      width: 100%;
      background-color: $headBgrAboveTable;
      padding-top: 10px;
      padding-bottom: 10px;
      z-index: 100;
      max-height: 200px;
      overflow: auto;
      .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 8px;
        position: relative;
        cursor: pointer;

        &.disable {
          cursor: not-allowed;
          .overlay-div {
            cursor: not-allowed;
          }
        }

        .overlay-div {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 2;
        }

        div {
          &.form-group {
            margin: 0;
            width: 30px;
          }
          &.item-name {
            width: 60%;
          }
          &.item-value {
            text-align: right;
            flex: 1;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .newbooking-form {
    .info-content {
      min-height: unset;
    }
    .booking-map-container {
      height: 350px;
      margin-bottom: 20px;
    }
  }
}

div#payfacto-secure-fields-modal {
  margin-top: 0px !important;
}
.pf-modal-footer {
  margin-top: 20px;
}
.modal-payfactor-cc {
  .payfacto-input-title {
    color: white !important;
    font-size: 14px !important;
  }
  .modal-payfactor-body {
    height: 625px;
    .ifPayCC {
      border: none;
      .pf-modal-footer {
        margin-top: 20px !important;
      }     
    }
  }
}
