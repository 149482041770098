$fontSizeText: 0.875rem;
$modalHeadBgr:#252830;
$modalBodyBgr:#363a44;
$formBackground:#252830;
$disableInputBackgroundColor : transparent;
$disableInputBorderColor: #252830;
$btnHeadHover:grey;
$btnBottomHover:grey;
$text:#e6e6e6;
$textPlaceholder: #5f6167;
$headBgrAboveTable:#1c1e24;
$headerTitleTable:#04BE76;
$darkText:#000;
$positiveBtnBgr:#04BE76;
$cloneBtn:#2E90FA;
$cancelBtn:#F04438;
$completeBtn:#FDB022;
$negativeBtnBgr:#DE6C59;
$border:#58617b;
$app-base-color: #e6e6e6;
$text-active-color: #04BE76;
$showError:#DE6C59;
$hasShowError: #a94442;
$updateButtonColor: #F2D45F;
$fontFamily: 'SF PRO DISPLAY', sans-serif;
$linkText: '#53b1fd';

//sidebar
$sidebarWidth: 200px;
$sidebarCollapsedWidth: 65px;
$menu-background: #252830;
$menu-inactive-color: #e6e6e6;
$menu-item-border: #20232a;
$menu-active-color: #04BE76;
$menu-icon-width: 30px;
$menu-icon-height: 25px;
$menu-icon-color: #63666f;

//Navbar
$navbar-background: #363a44;
$navbar-height: 60px;
$mobileNavbarheight: 50px;

//scrollbar
$scroll-track: #252830;
$scroll-thumb: #63666f;
$scroll-width: 10px;
$scroll-height: 10px;
$common-border-radius: 4px;

//form
$borderColor:#04BE76;
$sectionHeaderColor : #04BE76;
$iconColor: #04BE76;
$recentButtonColor: #04BE76; // new booking form
$autoPlacesRemoveColor : #63666f;

//basic
$page-content-background-color: #363a44;

//table
$table-row-hover-bg: #294945;
$table-row-hightlighted-bg: #30343D;
$tableHeaderMarginBottom : 10px;
$table-no-pagination-bg: #30343D;
$table-no-pagination-row-border: #252830;

// DropDown
$dropdown-bg: #1c1e24;

// margin, padding
$margin-sm: 5px;
$margin: 10px;
$margin-lg: 15px;
$margin-md: 20px;
$margin-xl: 30px;

$padding-sm: 5px;
$padding: 10px;
$padding-lg: 15px;
$padding-md: 20px;
$padding-xl: 30px;

// button header
$btnHeadernHoverOpacity: 0.6;

//booking status
$pendingStatusColor: #540f0f;
$preStatusColor: #540f0f;
$queueStatusColor: #bf6cff;
$offeredStatusColor: #bf6cff;
$confirmedStatusColor: #75cede;
$supplierAcceptedStatusColor:#04BE76;
$bookedStatusColor: #fdca00;
$arrivedStatusColor: #d57700;
$droppedOffStatusColor: #f52518;
$canceledByPassengerStatusColor: #c59a6f;
$canceledByDriverStatusColor: #630081;
$canceledByPartnerStatusColor: #8d5022;
$canceledByCCStatusColor: #f79776;
$noShowStatusColor: #000;
$actionStatusColor: #e3009f;
$engagedStatusColor: #00929b;
$canceledBymDispatcherStatusColor: #8c331d;
$canceledByCorpAdStatusColor: #5d250c;
$canceledByAPIStatusColor: #c66;
$canceledByWebBookingStatusColor: #a86363;
$canceledByTimeoutStatusColor: #5a4a42;
$completedStatusColor: #00572d;
$incidentStatusColor: #4752a3;
$statusColorWidth: 10px;
$VIPOptionsColor : #FF8A00;

// Trip status:
$startedStatusColor: #fdca00;
$intransitStatusColor: #00929b;

// placeholder
$placeholderOpacity: 0.3;
$columnFilterDropdownBg: #1c1e24;

//calendar
$reservedColor: #04BE76;
$overlappedColor: #F2D45F;
$availableColor: #363a44;
$reservationBlockColor: rgba(4, 190, 118, 0.3);

//header
$notificationCountBg: #f52c18;
$sosCountBg: #e6e6e6;

$commonMargin: 20px;

//map
$vancant: #80bb00;
$pob: #00929b;
$onTheWay: #fdca00;
$waiting: #e3009f;
$dispatch: #bf6cff;
$dropOff: #f52518;
$arrived: #d57700;
$unavailable: #979797;

$offline: #de6c59;

// loading bar, spiner
$loadingBarColor: #04BE76;
$spinerColor: #fff;

:root {
  --minHeigh-table: auto
}