@import './assets/styles/variables.scss';
@import './assets/styles/custom.scss';
@import './assets/styles/customReactBootstrap.scss';
@import './assets/styles/utils.scss';
@font-face {
  font-family: 'SF PRO DISPLAY';
  src: local('SF PRO DISPLAY Regular'),
    url('./assets/font/SFPRODISPLAYREGULAR.OTF') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF PRO DISPLAY';
  src: local('SF PRO DISPLAY Black Italic'),
    url('./assets/font/SFPRODISPLAYBLACKITALIC.OTF') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF PRO DISPLAY';
  src: local('SF PRO DISPLAY Bold'),
    url('./assets/font/SFPRODISPLAYBOLD.OTF') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF PRO DISPLAY';
  src: local('SF PRO DISPLAY Medium'),
    url('./assets/font/SFPRODISPLAYMEDIUM.OTF') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF PRO DISPLAY';
  src: local('SF PRO DISPLAY SemiBold Italic'),
    url('./assets/font/SFPRODISPLAYSEMIBOLDITALIC.OTF') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SF PRO DISPLAY';
  src: local('SF PRO DISPLAY UltraLight Italic'),
    url('./assets/font/SF-Pro-Display-LightItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

body {
  margin: 0;
  padding: 0;
  font-family: $fontFamily;
  // font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: $fontSizeText;
  background-color: $page-content-background-color;
}

.tooltip-inner {
  font-family: $fontFamily;
}

i.fa {
  font-size: 1.2em;
  margin-right: 4px;
}
.confirm-dialog {
  margin-top: 10%;
  .modal-footer {
    button.btn {
      margin-left: 20px;
    }
  }
}

p {
  margin-bottom: 0.55rem;
}

.white-text {
  color: #e6e6e6 !important;
  text-wrap: wrap;
}

.modal {
  z-index: 9999;
}
.mt-5 {
  margin-top: 5px;
}
.mt-2 {
  margin-top: 2px;
}

span.require {
  color: $showError;
}
.input-group-addon.action:hover {
  background: #ccc;
  color: $negativeBtnBgr;
}
button.btn span.glyphicon,
button.btn i.fa {
  margin-right: 5px;
}
.modal-footer .control-label {
  margin-right: 10px;
}
td.item-no-found {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  height: 50px;
  width: 100%;
  border-radius: 0 !important;
}
.upper {
  text-transform: uppercase;
}
.lower {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
a i.fa {
  cursor: pointer;
}
i.fa.fa-2x {
  font-size: 2em;
}
i.fa.fa-3x {
  font-size: 3em;
}
.intl-tel-input {
  width: 100%;
}

/*margin option*/
.mr-0 {
  margin: 0;
}
.mr-l-5 {
  margin-left: 5px;
}
.mr-l-10 {
  margin-left: 10px !important;
}
.mr-l-20 {
  margin-left: 20px;
}
.mr-r-5 {
  margin-right: 5px;
}
.mr-r-10 {
  margin-right: 10px;
}
.mr-r-20 {
  margin-right: 20px;
}
.mr-r-50 {
  margin-right: 50px;
}
.mr-l-50 {
  margin-left: 50px;
}
.mr-r-50 {
  margin-right: 50px;
}
.mr-t-0 {
  margin-top: 0px !important;
}
.mr-t-5 {
  margin-top: 5px;
}
.mr-t-10 {
  margin-top: 10px;
}
.mr-t-15 {
  margin-top: 15px;
}
.mr-t-20 {
  margin-top: 20px;
}
.mr-t-30 {
  margin-top: 30px;
}
.mr-b-10 {
  margin-bottom: 10px !important;
}
.mr-b-20 {
  margin-bottom: 20px !important;
}
.mr-b-5 {
  margin-bottom: 5px;
}
.mr-b-0 {
  margin-bottom: 0px !important;
}
.mr-b-50 {
  margin-bottom: 50px;
}
.mr-r-l-0 {
  margin-left: 0;
  margin-right: 0;
}
/*padding option*/
.pd-0 {
  padding: 0px;
}
.btn.btn-icon {
  margin: 0px;
  padding: 0px;
}
.pd-5 {
  padding: 5px;
}
.pd-r-5 {
  padding-right: 5px;
}
.pd-l-5 {
  padding-left: 5px;
}
.pd-l-60 {
  padding-left: 60px;
}

.pd-r-l-0 {
  padding-left: 0;
  padding-right: 0;
}

.pd-r-l-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.pd-r-l-60 {
  padding-left: 60px;
  padding-right: 60px;
}
.pd-10 {
  padding: 10px;
}

.pd-t-10 {
  padding-top: 10px;
}

.pd-t-15 {
  padding-top: 15px;
}
.pd-t-30 {
  padding-top: 30px;
}
.pd-b-100 {
  padding-bottom: 100px;
}
.pd-b-30 {
  padding-bottom: 30px;
}

.a-r-60 {
  right: 60px !important;
}

pd-15 {
  padding: 15px;
}
label {
  font-weight: normal;
}
th {
  font-weight: normal;
}
.red {
  color: $negativeBtnBgr;
}

i {
  cursor: pointer;
}
.block {
  display: block;
}
.form-inline .form-control {
  max-width: 100%;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.clickable {
  cursor: pointer;
}
.upper-case {
  text-transform: uppercase;
}
.lower-case {
  text-transform: lowercase;
}
.full-width {
  width: 100%;
}
.Select-multi-value-wrapper .Select-input {
  height: 32px;
}
.Select-control {
  height: 34px;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

.corp-traveler-address {
  min-height: 80px;
}

.corp-traveler-checkbox {
  clear: both;
}
.text-warning {
  color: #de6c59;
  &.fa-trash {
    color: #de6c59 !important;
  }
}

.text-soft-warning {
  color: #f2d45f;
}

.text-outstanding-warning {
  color: #f79009;
}

.fs-16 {
  font-size: 16px;
}
.fs-15 {
  font-size: 15px;
}

.fs-20 {
  font-size: 20px;
}

.pd-t-none {
  padding-top: 0px;
}

.pd-b-none {
  padding-bottom: 0px;
}

.vs-10 {
  height: 10px;
}

.vs-20 {
  height: 20px;
}

.vs-30 {
  height: 30px;
}

.hs-20 {
  width: 20px;
  display: inline-block;
}

.error-img-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .error-image {
    max-width: 600px;
    height: auto;
    max-height: 400px;
  }
  .error-message-container {
    color: $menu-icon-color;
    font-size: 2.75rem;
    .oops {
      color: $app-base-color;
      text-transform: uppercase;
      font-size: 3.5rem;
      margin-top: 20px;
    }
  }
}

.send-receipt-modal .message-item {
  color: #a94442;
}
.error-img {
  width: 100%;
}
.send-receipt-modal .message-item {
  color: #a94442;
}
.modal-header {
  button.close {
    background-image: url('./assets/images/icons/close_icon.svg');
  }
}

.upload-icon {
  height: 100%;
  width: 40px;
  background-size: auto 16px;
  background-position: center center;
  background-repeat: no-repeat;
  &:hover {
    cursor: pointer;
  }
  &.upload {
    background-image: url('./assets/images/icons/upload.svg');
  }
  &.remove {
    background-image: url('./assets/images/icons/delete.svg');
  }
}

.delete-icon {
  width: 16px;
  height: 30px;
  background-image: url('./assets/images/icons/delete.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;
  &:hover {
    cursor: pointer;
  }
}

body {
  > div {
    &:not(#root) {
      &:not([role='dialog']) {
        &:not([role='tooltip']) {
          &:not(.modal-backdrop) {
            z-index: 99999 !important;
            background: $menu-background !important;
            border-color: transparent !important;
            > div {
              div {
                &:not(.fixedDataTableColumnResizerLineLayout_mouseArea) {
                  background: $menu-background !important;
                  padding: 3px 12px !important;
                }
                span {
                  &:first-child {
                    color: $text-active-color;
                  }
                }
                &:hover {
                  background: $page-content-background-color !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.text-tranform-none {
  text-transform: none !important;
}

.text-align-center {
  text-align: center;
}

.body-import {
  display: flex;
  justify-content: space-between;
}

.linkTemplate {
  color: #04be76;
}

.linkTemplate:hover,
.linkTemplate:active {
  color: #04be76;
  opacity: 0.6;
}
.qup-green {
  color: #04be76;
}
.qup-red {
  color: #de6c59;
}
.qup-bg-green {
  background-color: #04be76;
}
.qup-bg-red {
  background-color: #de6c59;
}
.not-margin {
  margin: 0 !important;
}

.modal-dialog.serviceModal .modal-content {
  overflow: initial;
}

.modal-report-table-header {
  white-space: nowrap;
}

#driverDetail {
  .tab-content {
    padding-top: 30px;
  }
  .nav-tabs > a {
    font-size: 16px;
  }
  .tabFirst {
    padding-left: 0px;
  }
  #driverDetail-tab-1 {
    padding-left: 0px;
  }
}

#qrContainer {
  text-align: center;
}

#btnDownloadQR {
  width: 250px;
  background-color: #04be76;
  color: white;
  margin-top: 20px;
  display: block;
  margin: 20px auto 0 auto;
}

.questLink {
  margin-top: 30px;
  .input-group {
    background-color: #24272e;
  }
}
