@import "../../../assets/styles/variables.scss";
.table-action-popover {
  background-color: $dropdown-bg;
  width: 150px;
  margin-top: -1px !important;
  border: none;
  box-shadow: none;
  transition: none;
  border-radius: $common-border-radius;
  border-top-right-radius: 0;
  .arrow {
    display: none;
  }
  .popover-content {
    padding: 0;
  }
  .actions {
    list-style: none;
    background-color: $dropdown-bg;
    color: $app-base-color;
    margin: 0;
    padding: 10px;
    border: none;
    border-radius: $common-border-radius;
    width: 100%;
    a {
      padding: 5px;
      &:hover {
        background-color: $page-content-background-color;
        cursor: pointer;
      }
    }
  }
}