@import './variables.scss';

$primary: $text-active-color;

.form-control, .btn, .input-group-text, .dropdown-menu {
  font-size: $fontSizeText;
}

.form-control:focus {
  box-shadow: 0 0 0 1px rgba(4, 190, 118, 1);
  border-color: $primary;
  background-color: $formBackground;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  color: #333;
  &:hover,
  &:focus,
  &:active,
  &.active,
  &:disabled {
    background-color: transparent;
    border-color: darken($primary, 10%);
  }
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: $text-active-color;
  background-color: transparent;
  border-color: $text-active-color;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.form-control:focus {
  color: white;
}

.input-group {
  align-items: flex-start;
}

body {
  .row, .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    margin-left: -10px;
    margin-right: -10px;
  }
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl{
    padding-left: 10px;
    padding-right: 10px;
  }
  .col-xs-1,
  .col-sm-1,
  .col-md-1,
  .col-lg-1,
  .col-xs-2,
  .col-sm-2,
  .col-md-2,
  .col-lg-2,
  .col-xs-3,
  .col-sm-3,
  .col-md-3,
  .col-lg-3,
  .col-xs-4,
  .col-sm-4,
  .col-md-4,
  .col-lg-4,
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5,
  .col-xs-6,
  .col-sm-6,
  .col-md-6,
  .col-lg-6,
  .col-xs-7,
  .col-sm-7,
  .col-md-7,
  .col-lg-7,
  .col-xs-8,
  .col-sm-8,
  .col-md-8,
  .col-lg-8,
  .col-xs-9,
  .col-sm-9,
  .col-md-9,
  .col-lg-9,
  .col-xs-10,
  .col-sm-10,
  .col-md-10,
  .col-lg-10,
  .col-xs-11,
  .col-sm-11,
  .col-md-11,
  .col-lg-11,
  .col-xs-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    padding-right: 10px;
    padding-left: 10px;
  }
}

body .row.rowNotMargin {
  margin-left: 10px;
  margin-right: 10px;
}