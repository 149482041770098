@import '../../assets/styles/variables.scss';

.cc-drop-down {
  &.show {
    .btn.dropdown-toggle {
      background-color: $menu-background;
      color: $text-active-color;
      .dropdown-icon-add {
        svg path {
          fill: #04BE76;
        }
      }
    }
  }
  #timeFilterType-dropdown {
    color: $text-active-color;
    padding-left: 0px;
  }
  #select-column-dropdown {
    padding: 0px;
  }
  &.drop-showHide-column {
    display: flex;
    margin: 0 15px;
    padding-right: 10px;
    .cc-checkbox-wrapper.text-ellipsis {
      white-space: normal;
    }
    &.column-filter .dropdown-menu .menu-container {
      width: 520px;
    }
  }
  .dropDown_apply {
    text-align: center;
  }
  .btn.dropdown-toggle {
    background-color: transparent;
    color: $app-base-color;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    opacity: 1 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 10px 10px 10px 15px;
    max-width: 420px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 70px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .addIcon {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      color: white;
      font-weight: bold;
    }
    &:hover {
      color: $text-active-color;
      .dropdown-icon-add {
        svg path {
          fill: #04BE76;
        }
      }
    }
    .caret {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: $text-active-color;
    }
  }
  .dropdown-menu {
    background-color: $menu-background;
    box-shadow: 0 4px 12px rgba(6, 6, 6, 0.175);
    color: $app-base-color;
    border: none;
    margin: 0;
    border-top-left-radius: 0;
    min-width: 250px;
    max-width: 288px;
    padding: 5px 5px 10px;
    &.pull-right {
      left: unset;
      right: 0;
    }
    .search-container {
      position: relative;
      padding: 5px 0px;
      .search-input {
        background-color: $page-content-background-color;
        color: $app-base-color;
        border: none !important;
      }
    }
    .clear-all-item {
      display: block;
      padding: 5px 15px;
      color: $text-active-color;
      &:hover {
        text-decoration: underline;
        color: $text-active-color;
        cursor: pointer;
        opacity: 0.5;
      }
    }
    .menu-container {
      position: relative;
      max-height: 250px;
      overflow: auto;
      ul {
        margin: 0;
        a {
          display: block;
          padding: 5px 15px;
          clear: both;
          font-weight: normal;
          line-height: 1.42857143;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          color: inherit;
          text-decoration: none !important;
          outline: none !important;
          &:hover,
          &:focus {
            background-color: $page-content-background-color;
          }
        }
        &.is-selected {
          a {
            background-color: $page-content-background-color;
          }
        }
        &.all-item-auto-added {
          width: 100% !important;
        }
        &.disabled {
          a {
            cursor: not-allowed;
          }
        }
        &.no-result {
          a {
            color: $menu-icon-color;
          }
        }
      }
      .dropdown-selectbox {
        margin: 0;
        label {
          &:before {
            border: 1px solid $menu-icon-color;
          }
        }
      }
    }
    .dropdown-footer {
      padding: 5px 15px;
    }
  }
  &.cueActionDropDown {
    .dropdown-menu {
      position: fixed !important;
      top: 0px !important;
      right: 0px !important;
      left: auto !important;
      bottom: 0px !important;
      transform: unset !important;
    }
    .menu-container {
      max-height: 100% !important;
    }
    .rearrangeCueAction {
      .numberItem {
        display: inline-block;
        font-size: 12px;
        text-align: center;
        min-width: 20px;
        color: #04be76;
        margin-right: 2px;
      }
    }
    .all_item_action {
      padding-left: 37px;
    }
    .cc-checkbox-wrapper label {
      font-weight: bold;
    }
    .sortCueAction {
      overflow: hidden;
      .titleSort {
        color: #04be76;
        padding-left: 15px;
      }
    }
    .selectbox-all {
      margin-left: 17px !important;
    }

    .fa-plane {
      margin-left: 5px !important;
      margin-right: 0px !important;
    }
    .remove-icon {
      width: 18px;
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 5px;
    }
    @media only screen and (max-width: 768px) {
      .dropdown-menu {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px !important;
        right: 0px;
        .menu-container {
          width: 100% !important;
        }
      }
    }
  }
  .cueCBActions {
    width: 100%;
  }
  .btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: $menu-background !important;
  border-color: unset;
}
}
.cb-cueAction {
  position: relative;
  input.cc-checkbox {
    left: 0px;
    z-index: 99999;
    margin-top: 0px;
    width: 25px;
    accent-color: #363a44;
    height: 25px;
    top: 0px;
    opacity: 0;
  }
}

