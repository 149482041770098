@import "../../../assets/styles/variables.scss";

.qup-pagination-wrapper {
  padding: 12px 15px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: $menu-background;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  color: $app-base-color;
  position: relative;
  .left-controls {
    .form-control {
      width: auto !important;
      cursor: pointer;
      &:focus {
        box-shadow: none;
      }
      &.select-page-item,
      &.select-page {
        background-color: $page-content-background-color;
        color: $app-base-color;
        border: none;
        border-radius: 5px;
        height: 28px;
        padding: 6px 12px;
      }
    }
    .page-input {
      border: 1px solid $text-active-color;
      height: 28px;
      text-align: center;
      margin-right: 5px;
      line-height: 28px;
      padding: 6px;
      width: 60px !important;
      &.error-page {
        border-color: $showError;
      }
    }
    > * {
      display: inline-block;
      > * {
        display: inline;
      }
    }
  }
  .right-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
    .shown-items {
      margin-right: 20px;
    }
  }
  &.pagination-no-data {
    .right-controls {
      display: none;
    }
  }
  ul.paginations {
    display: inline-block;
    padding-left: 0;
    border-radius: 4px;
    list-style-type: none;
    color: $app-base-color;
    margin: 0;
    li {
      display: inline;
      div {
        color: $menu-icon-color;
        display: inline-block;
        margin: 0 10px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        &.active {
          color: $app-base-color;
          background-color: $page-content-background-color;
        }
        &:hover {
          color: $app-base-color;
          background-color: $page-content-background-color;
          text-decoration: none;
        }
        &:focus {
          text-decoration: none;
        }
        &.transition {
          background-color: $dropdown-bg;
          &:focus {
            color: $app-base-color;
          }
          &:hover {
            background-color: $page-content-background-color;
            color: $app-base-color;
          }
        }
      }
    }
  }
  .footer-loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $menu-background;
    .spinner-icon { 
      color: $menu-icon-color;
    }
  }
}

@media only screen and (max-width: 768px) {
  .qup-pagination-wrapper  {
    flex-direction: column;
    align-items: flex-start;
    .left-controls {
      display: flex;
      flex-flow: row wrap;
      > * {
        margin-bottom: 15px;
      }
    }
    .right-controls {
      justify-content: flex-start;
      > * {
        margin-bottom: 15px;
      }
    }
  }
}